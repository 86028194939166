import { FC, ReactNode } from 'react';

import { Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { CalendarOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';

import translation from 'lux/components/translation';
import { VacancyConversionStatistics } from 'lux/models/vacancyConversionStatistics';
import Card, { CardTitle } from 'lux/pages/VacancyView/components/VacancyStatTab/Card';
import LegendPoint from 'lux/pages/VacancyView/components/VacancyStatTab/LegendPoint';
import QualityConversionText from 'lux/pages/VacancyView/components/VacancyStatTab/QualityConversionText';
import formatToInt from 'lux/utils/formatToInt';

import { formatHumanDate, formatMergedDates } from 'lux/pages/VacancyView/components/VacancyStatTab/Cards/dateUtils';
import { VacancyComparisonQuality } from 'lux/pages/VacancyView/components/VacancyStatTab/Cards/types';

import styles from './common-card.less';

const TrlKeys = {
    viewCount: 'vacancy.stat.title.views',
    responseCount: 'vacancy.stat.title.responses',
    conversion: 'vacancy.stat.title.conversion',
};

interface CommonCard {
    data: VacancyConversionStatistics;
    qualityResult: VacancyComparisonQuality;
}

interface MetricColumnInfoProps {
    value: string | number | ReactNode;
    children: ReactNode;
    dataQa: string;
}

const MetricColumnInfo: FC<MetricColumnInfoProps> = ({ value, children, dataQa }) => {
    return (
        <VSpacingContainer default={8}>
            <Text Element="strong" typography="subtitle-1-semibold" data-qa={dataQa}>
                {value}
            </Text>
            <div className={styles.metricColumnInfoContent}>{children}</div>
        </VSpacingContainer>
    );
};

const CommonCard: TranslatedComponent<CommonCard> = ({ data, qualityResult, trls }) => {
    const viewCount = formatToInt(data?.viewCount) || 0;
    const responseCount = formatToInt(data?.responseCount) || 0;
    const conversionPercent = NumberFormatter.format(String((data.viewToResponseConversion || 0) * 100), {
        decimalLength: 1,
    });

    const dateRange =
        data.startDate && data.endDate
            ? formatMergedDates(data.startDate, data.endDate)
            : formatHumanDate(data.startDate);

    return (
        <Card title={<CardTitle icon={<CalendarOutlinedSize16 initial="secondary" />} text={dateRange ?? ''} />}>
            <div className={styles.metricWrap}>
                <MetricColumnInfo value={viewCount} dataQa="metric-info-view">
                    <LegendPoint color="blue" />
                    <Text>{trls[TrlKeys.viewCount]}</Text>
                </MetricColumnInfo>
                <MetricColumnInfo value={responseCount} dataQa="metric-info-response">
                    <LegendPoint color="green" />
                    <Text>{trls[TrlKeys.responseCount]}</Text>
                </MetricColumnInfo>
                <MetricColumnInfo
                    value={<QualityConversionText text={`${conversionPercent}%`} quality={qualityResult} />}
                    dataQa="metric-info-conversion"
                >
                    <Text>{trls[TrlKeys.conversion]}</Text>
                </MetricColumnInfo>
            </div>
        </Card>
    );
};

export default translation(CommonCard);
