import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import styles from './market-range-text.less';

const TrlKeys = {
    ranges: {
        offMarket: 'vacancy.salaryMarketRangeChart.market.range.offMarket',
        inMarket: 'vacancy.salaryMarketRangeChart.market.range.inMarket',
        aboveMarket: 'vacancy.salaryMarketRangeChart.market.range.aboveMarket',
    },
};

const MarketRangeText: TranslatedComponent = ({ trls }) => {
    return (
        <div className={styles.marketRangeText}>
            {Object.keys(TrlKeys.ranges).map((key) => (
                <Text key={key} typography="label-2-regular">
                    {trls[TrlKeys.ranges[key]]}
                </Text>
            ))}
        </div>
    );
};

export default translation(MarketRangeText);
