import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import vacancySimilarItemForEmployerElementShown, {
    RecommendationType,
} from '@hh.ru/analytics-js-events/build/xhh/employer/vacancy/vacancy_similar_item_for_employer_element_shown';
import Button, { ButtonScale, ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import { H1Section } from 'bloko/blocks/header';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'lux/components/Notifications/Provider';
import { RelatedVacanciesType } from 'lux/components/RelatedVacancies/relatedVacanciesTypes';
import VacancySearchItem from 'lux/components/VacancySearchItem';
import Source from 'lux/components/VacancySearchItem/types/Source';
import translation from 'lux/components/translation';
import useOnOffState from 'lux/hooks/useOnOffState';
import { RelatedVacancies } from 'lux/models/relatedVacancies';
import { pushRelatedVacanciesForEmployerAction } from 'lux/models/relatedVacanciesForEmployer';
import { addUserLabelsForVacancies } from 'lux/models/userLabelsForVacancies/userLabels';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

import Title from 'lux/components/RelatedEmployerVacancies/Title';

const TrlKeys = {
    loadMore: 'vacancy.relatedVacancyForEmployer.loadMore',
};

interface RelatedEmployerVacaicesProps {
    initialType: RelatedVacanciesType;
}

declare global {
    interface FetcherGetApi {
        '/shards/vacancy/related_vacancies': {
            queryParams: {
                vacancyId?: number;
                employerId?: number | null;
                searchSessionId: string | null;
                page: number;
                type?: RelatedVacanciesType;
            };
            response: RelatedVacancies;
        };
    }
}

const RelatedEmployerVacancies: TranslatedComponent<RelatedEmployerVacaicesProps> = ({ initialType, trls }) => {
    const [isLoading, setIsLoadingOn, setIsLoadingOff] = useOnOffState(false);

    const currentPageNumber = useRef(1);
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;
    const relatedVacanciesForEmployer = useSelector((state) => state.relatedVacanciesForEmployer);
    const searchSessionId = useSelector((state) => state.searchSessionId);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const employerId = useSelector((state) => state.vacancyView.company.id);

    const { type, totalPages, resultsFound } = relatedVacanciesForEmployer;
    const [showLoadMore, setShowLoadMore] = useState(totalPages && currentPageNumber.current < totalPages);

    const loadMoreVacancies = useCallback(async () => {
        if (isLoading) {
            return;
        }
        setIsLoadingOn();
        let response;
        try {
            response = await fetcher.get('/shards/vacancy/related_vacancies', {
                params: {
                    vacancyId,
                    employerId,
                    page: currentPageNumber.current,
                    searchSessionId,
                    type,
                },
            });
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
            return;
        } finally {
            setIsLoadingOff();
        }
        const actions = response.vacancies.map(({ vacancyId, userLabels }) =>
            addUserLabelsForVacancies({ vacancyId, labels: userLabels })
        );

        dispatch([...actions, pushRelatedVacanciesForEmployerAction(response.vacancies)]);
        currentPageNumber.current += 1;
        if (totalPages && currentPageNumber.current >= totalPages) {
            setShowLoadMore(false);
        } else {
            setShowLoadMore(true);
        }
    }, [
        isLoading,
        setIsLoadingOn,
        dispatch,
        totalPages,
        vacancyId,
        employerId,
        searchSessionId,
        type,
        addNotification,
        setIsLoadingOff,
    ]);

    if (!resultsFound) {
        return null;
    }

    return (
        <div className="vacancy-section" data-qa="vacancy-view-employer-vacancies-from-search">
            <H1Section Element="h2">
                <Title type={initialType || type} />
            </H1Section>
            <VSpacing base={4} />
            <div ref={wrapperRef}>
                {relatedVacanciesForEmployer?.vacancies?.map((vacancy) => (
                    <ElementShownAnchor
                        fn={vacancySimilarItemForEmployerElementShown}
                        recommendationType={type as RecommendationType}
                        vacancyId={vacancyId || 0}
                        relatedVacancyId={vacancy.vacancyId}
                        key={vacancy.vacancyId}
                    >
                        <VacancySearchItem
                            vacancy={vacancy}
                            vacancySource={Source.EmployerRelatedVacancies}
                            hhtmFromLabel={`${type || ''}_employer_vacancies`}
                            isXs={isXs}
                        />
                    </ElementShownAnchor>
                ))}
            </div>
            {isLoading && (
                <div className="related-vacancies-loading-indicator">
                    <Gap top>
                        <Loading scale={LoadingScale.Small} />
                    </Gap>
                </div>
            )}
            {!isLoading && showLoadMore && (
                <Gap top>
                    <Button
                        scale={ButtonScale.Small}
                        kind={ButtonKind.Secondary}
                        appearance={ButtonAppearance.Outlined}
                        onClick={loadMoreVacancies}
                    >
                        {trls[TrlKeys.loadMore]}
                    </Button>
                </Gap>
            )}
        </div>
    );
};

export default translation(RelatedEmployerVacancies);
