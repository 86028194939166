import { setClickmeAutoCampaignInfo } from 'lux/models/clickmeAutoCampaign';
import {
    fetchVacancyUpgradesStart,
    fetchVacancyUpgradesSuccess,
    fetchVacancyUpgradesFailed,
} from 'lux/models/employerVacancies/vacancyUpgrades';
import fetcher from 'lux/modules/fetcher';

export default ({ params: { employerId, vacancyId } }) =>
    async (dispatch) => {
        dispatch(fetchVacancyUpgradesStart(vacancyId));

        try {
            const { backurl, suitableUpgrades, clickmeInfo } = await fetcher.get(
                '/shards/employer/vacancies/suitableUpgrades',
                {
                    params: {
                        employerId,
                        vacancyId,
                    },
                }
            );

            dispatch(fetchVacancyUpgradesSuccess({ id: vacancyId, data: { backurl, suitableUpgrades } }));

            if (clickmeInfo) {
                dispatch(setClickmeAutoCampaignInfo(clickmeInfo));
            }
        } catch (err) {
            dispatch(fetchVacancyUpgradesFailed(vacancyId));

            throw err;
        }
    };
