import { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import Column, { ColumnsRow } from 'bloko/blocks/column';

import { useSelector } from 'lux/modules/useSelector';
import UserLabel from 'lux/modules/userLabels';

import VacancySignUpBlock from 'lux/components/VacancyView/SignUpBlock';
import VacancyAdditionalAccepts from 'lux/components/VacancyView/VacancyAdditionalAccepts';
import VacancyBlockEmployer from 'lux/components/VacancyView/VacancyBlock/Employer';
import VacancyBlockTop from 'lux/components/VacancyView/VacancyBlock/Top';
import VacancyContacts from 'lux/components/VacancyView/VacancyContacts';
import VacancyDescription from 'lux/components/VacancyView/VacancyDescription';
import VacancyDriverLicense from 'lux/components/VacancyView/VacancyDriverLicense';
import VacancyFooter from 'lux/components/VacancyView/VacancyFooter';
import VacancyIsArchived from 'lux/components/VacancyView/VacancyIsArchived';
import VacancyKeySkills from 'lux/components/VacancyView/VacancyKeySkills';
import VacancyMap from 'lux/components/VacancyView/VacancyMap';
import VacancyPreviewTools from 'lux/components/VacancyView/VacancyPreviewTools';
import VacancyResponseQuestions from 'lux/components/VacancyView/VacancyResponseQuestions';
import VacancySkillSurvey from 'lux/components/VacancyView/VacancySkillSurvey';
import useIsVisibleBody from 'lux/components/VacancyView/hooks/useIsVisibleBody';

const VacancyBodyRedesigned = ({ contactsNodeRef, vacancyBodyFooterNodeRef }) => {
    const resumeFormNodeRef = useRef();
    const { isVisibleBody, setIsVisibleBody } = useIsVisibleBody();
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const userLabels = useSelector(({ userLabelsForVacancies }) => userLabelsForVacancies[vacancyId]);
    const responseStatus = useSelector((state) =>
        vacancyId ? state.applicantVacancyResponseStatuses[vacancyId] : undefined
    );

    const hasResponse = Boolean(responseStatus?.usedResumeIds?.length);
    const hasQuestion = userLabels?.includes(UserLabel.Question);
    const isVacancyQuestionSingleBlockExp = useSelector((state) => state.isVacancyQuestionSingleBlockExp);
    const isVacancyQuestionDoubleBlockExp = useSelector((state) => state.isVacancyQuestionDoubleBlockExp);

    return (
        <Fragment>
            <VacancyBlockTop
                resumeFormNodeRef={resumeFormNodeRef}
                contactsNodeRef={contactsNodeRef}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                isChatQuestionExp={isVacancyQuestionDoubleBlockExp}
                hasQuestion={
                    (isVacancyQuestionSingleBlockExp || isVacancyQuestionDoubleBlockExp) && hasQuestion && !hasResponse
                }
            />
            <Column xs="4" s="8" m="12" l="0" container>
                <VacancyBlockEmployer vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
            </Column>
            <VacancySignUpBlock blockRef={vacancyBodyFooterNodeRef} />
            <VacancyIsArchived isHiddenSwitcher={isVisibleBody} setVisibilityBody={setIsVisibleBody} />
            {isVisibleBody && (
                <Fragment>
                    <ColumnsRow>
                        <Column xs="4" s="8" m="12" l="10">
                            <div className="vacancy-description">
                                <VacancyDescription />
                                <VacancyAdditionalAccepts />
                                <VacancyKeySkills />
                                <VacancyDriverLicense />
                                <VacancySkillSurvey />
                                <VacancyContacts
                                    isRedesign
                                    blockRef={contactsNodeRef}
                                    vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                                />
                                <VacancyResponseQuestions
                                    type={
                                        (isVacancyQuestionSingleBlockExp || isVacancyQuestionDoubleBlockExp) &&
                                        !hasQuestion &&
                                        !hasResponse
                                            ? 'question'
                                            : 'response'
                                    }
                                    vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                                    hasSuggest
                                />
                                <VacancyMap isRedesign />
                                <VacancyPreviewTools />
                            </div>
                        </Column>
                    </ColumnsRow>
                    <VacancyFooter vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} isRedesign withBanners />
                </Fragment>
            )}
        </Fragment>
    );
};

VacancyBodyRedesigned.propTypes = {
    contactsNodeRef: PropTypes.object,
    vacancyBodyFooterNodeRef: PropTypes.object,
};

export default VacancyBodyRedesigned;
