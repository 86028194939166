import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import { ArrowUpOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { toggleVacancyOptionSuccess, toggleVacancyOptionError } from 'lux/components/Notifications/EmployerVacancies';
import { useNotification } from 'lux/components/Notifications/Provider';
import { ADD_MODAL, MODAL_BUY_OPTION } from 'lux/components/VacancyModals/constants';
import translation from 'lux/components/translation';
import { refreshManagerVacancy } from 'lux/models/employerVacancies/managerVacancies';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

import getMenuButtonIconProps from 'lux/components/VacancyActions/getMenuButtonIconProps';

export const OPTION_ACTIONS = {
    enable: 'enable',
    disable: 'disable',
};

const ACTION_ERROR_KEYS = [
    'FAILED_TO_DELETE_EXPIRED',
    'PREMIUM_ALREDY_SET',
    'PREMIUM_NOT_SET',
    'FAILED_TO_DELETE',
    'NOT_ZP_VACANCY',
    'FAILED_TO_SPEND_SERVICE',
    'FAILED_TO_RETURN_SERVICE',
    'FAILED_TO_GET_SERVICE_COUNT',
    'NOT_ENOUGH_QUOTAS',
    'VACANCY_IS_NOT_ACTIVE',
];

const OptionAction = ({
    Component,
    triggerType,
    hasActiveOption,
    vacancyId,
    vacancyName,
    additionalAnalyticsParams,
    dispatchModal,
    onClose,
    trls,
}) => {
    const action = hasActiveOption ? OPTION_ACTIONS.disable : OPTION_ACTIONS.enable;
    const [isLoading, setLoading] = useState(false);
    const location = useSelector((state) => state.router.location);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const triggerName = 'option-toggle';
    const iconProps = getMenuButtonIconProps({
        triggerType,
        triggerName,
        icon: <ArrowUpOutlinedSize24 />,
    });

    const openModal = useCallback(() => {
        dispatchModal({
            type: ADD_MODAL,
            payload: {
                modalType: MODAL_BUY_OPTION,
            },
        });
    }, [dispatchModal]);

    const toggleOption = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetcher.post(
                `/shards/employer/vacancies_dashboard/toggle_vacancy_option?vacancyId=${vacancyId}&action=${action}`
            );
            if (location.pathname.match('/vacancy/')) {
                dispatch(push(`${location.pathname}${response.data.query}`));
            } else {
                const vacancy = (
                    await fetcher.get('/shards/employer/vacancies/get_full_vacancy', { params: { vacancyId } })
                ).vacancy;
                dispatch(refreshManagerVacancy(vacancy));
                addNotification(toggleVacancyOptionSuccess, { props: { vacancyName, action } });
            }
        } catch (error) {
            const errorKey = error?.response?.data?.error?.[0]?.key;
            if (ACTION_ERROR_KEYS.includes(errorKey)) {
                addNotification(toggleVacancyOptionError, { props: { errorKey } });
            } else if (errorKey === 'NOT_ENOUGH_SERVICES') {
                openModal();
            } else {
                defaultRequestErrorHandler(error, addNotification);
            }
        } finally {
            onClose?.();
            setLoading(false);
        }
    }, [action, addNotification, dispatch, location.pathname, onClose, openModal, vacancyId, vacancyName]);

    return (
        <Component
            triggerName={triggerName}
            triggerType={triggerType}
            onTrigger={toggleOption}
            isPermitted
            isLoading={isLoading}
            additionalAnalyticsParams={{ ...additionalAnalyticsParams, action }}
            {...iconProps}
        >
            {trls[OptionAction.trls[action]]}
        </Component>
    );
};

OptionAction.trls = {
    enable: 'vacancy.enableOption.trigger',
    disable: 'vacancy.disableOption.trigger',
};

OptionAction.propTypes = {
    Component: PropTypes.elementType.isRequired,
    triggerType: PropTypes.string,
    hasActiveOption: PropTypes.bool,
    vacancyId: PropTypes.number,
    vacancyName: PropTypes.string,
    dispatchModal: PropTypes.func,
    onClose: PropTypes.func,
    additionalAnalyticsParams: PropTypes.object,
    trls: PropTypes.object,
};

export default translation(OptionAction);
