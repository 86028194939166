import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { VSpacingContainer } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';

import {
    VacancyConversionStatistics,
    LoadingState,
    setVacancyConversionStatistics,
} from 'lux/models/vacancyConversionStatistics';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';

import Cards from 'lux/pages/VacancyView/components/VacancyStatTab/Cards';
import Chart from 'lux/pages/VacancyView/components/VacancyStatTab/Chart';
import { prepareReportData } from 'lux/pages/VacancyView/components/VacancyStatTab/Chart/utils';
import LoadingResult from 'lux/pages/VacancyView/components/VacancyStatTab/LoadingResult';

declare global {
    interface FetcherGetApi {
        '/shards/vacancy/get_vacancy_conversion': {
            queryParams: {
                vacancyId?: number;
            };
            response: VacancyConversionStatistics;
        };
    }
}

const VacancyStatTab: FC = () => {
    const dispatch = useDispatch();
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const { loadingState, data } = useSelector((state) => state.vacancyConversionStatistics);
    const preparedChartData = prepareReportData(data);
    const isShow = preparedChartData && data;

    const fetchVacancyConversion = useCallback(async () => {
        try {
            const response = await fetcher.get('/shards/vacancy/get_vacancy_conversion', {
                params: { vacancyId },
            });

            dispatch(
                setVacancyConversionStatistics({
                    loadingState: LoadingState.SUCCESS,
                    data: response,
                })
            );
        } catch (error) {
            dispatch(
                setVacancyConversionStatistics({
                    loadingState: LoadingState.ERROR,
                    data: null,
                })
            );
        }
    }, [dispatch, vacancyId]);

    useEffect(() => {
        if (loadingState === LoadingState.NONE) {
            void fetchVacancyConversion();
        }
    }, [fetchVacancyConversion, loadingState]);

    return (
        <ColumnsRow>
            <Column xs="4" s="8" m="8" l="10">
                <LoadingResult loadingState={loadingState} />
                {isShow && (
                    <VSpacingContainer default={20}>
                        <Cards data={data} />
                        <Chart data={preparedChartData} />
                    </VSpacingContainer>
                )}
            </Column>
        </ColumnsRow>
    );
};

export default VacancyStatTab;
