import PropTypes from 'prop-types';

const vacancyView = PropTypes.shape({
    specializations: PropTypes.shape({
        profArea: PropTypes.arrayOf(
            PropTypes.shape({
                '@id': PropTypes.number,
                trl: PropTypes.string,
            })
        ),
    }),
    status: PropTypes.shape({
        archived: PropTypes.bool,
    }),
    area: PropTypes.shape({
        areaCatalogTitle: PropTypes.string,
    }),
    company: PropTypes.shape({
        visibleName: PropTypes.string,
    }),
    name: PropTypes.string,
});

export default { vacancyView };
