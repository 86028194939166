import { VacancyConversionStatistics, ComparisonResult } from 'lux/models/vacancyConversionStatistics';

import { VacancyComparisonQuality } from 'lux/pages/VacancyView/components/VacancyStatTab/Cards/types';

const getComparisonQuality = (
    conversionComparison: VacancyConversionStatistics['viewToResponseConversionComparison']
): VacancyComparisonQuality => {
    if (!conversionComparison?.comparisonResult || !conversionComparison?.value) {
        return 'noCalc';
    }

    const { comparisonResult, value } = conversionComparison;

    if (
        (comparisonResult === ComparisonResult.HIGHER && value > 60) ||
        (comparisonResult === ComparisonResult.LOWER && value < 40)
    ) {
        return 'high';
    }

    if (value >= 40 && value <= 60) {
        return 'mid';
    }

    return 'low';
};

export default getComparisonQuality;
