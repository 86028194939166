import { useState } from 'react';

import Button, { ButtonScale, ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import Modal, { ModalHeader, ModalTitle, ModalFooter, ModalContent, ModalError } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import useOnOffState from 'lux/hooks/useOnOffState';
import { UserType } from 'lux/models/userType';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';

import callTrackingDisableSuccess, {
    callTrackingAlreadyDisabled,
} from 'lux/pages/VacancyView/components/DisableCallTrackingNotification';

declare global {
    interface FetcherPostApi {
        '/shards/call_tracking/disable': {
            body: {
                vacancyId: number;
            };
            queryParams: void;
            response: {
                status: string;
            };
        };
    }
}

const TrlKeys = {
    disableCT: 'admin.callTracking.vacancy.disable',
    disable: 'admin.callTracking.vacancy.disable.submit',
    cancel: 'form.submit.cancel',
    disableCTWarning: 'admin.callTracking.vacancy.disable.submit.warning',
    defaultError: 'page.4xx.description',
};

const DisableCallTracking: TranslatedComponent<{ vacancyId: number }> = ({ trls, vacancyId }) => {
    const { addNotification } = useNotification();
    const userType = useSelector(({ userType }) => userType);
    const canDisableCallTracking = useSelector(({ vacancyView }) => vacancyView.canDisableCallTracking);
    const [isModalVisible, showModal, hideModal] = useOnOffState(false);
    const [isBlockButtonDisabled, setBlockButtonDisabled] = useState(false);
    const [callTrackingDisableError, setCallTrackingDisableError] = useState('');
    const [isCallTrackingDisableSending, setCallTrackingDisableSending] = useState(false);

    if (userType !== UserType.BackOffice || canDisableCallTracking === undefined) {
        return null;
    }

    const closeModal = () => {
        setCallTrackingDisableError('');
        hideModal();
    };

    const onDisableCallTrackingClick = async () => {
        if (isCallTrackingDisableSending) {
            return;
        }
        setCallTrackingDisableSending(true);
        setCallTrackingDisableError('');
        let result;
        try {
            result = await fetcher.post('/shards/call_tracking/disable', { vacancyId });
        } catch (error) {
            setCallTrackingDisableError(trls[TrlKeys.defaultError]);
            setCallTrackingDisableSending(false);
            return;
        }

        addNotification(
            result.data?.status === 'RULE_ALREADY_EXISTS' ? callTrackingAlreadyDisabled : callTrackingDisableSuccess
        );
        setCallTrackingDisableSending(false);
        setBlockButtonDisabled(true);
        hideModal();
    };

    return (
        <>
            <VSpacing base={6} />
            <Button
                disabled={!canDisableCallTracking || isBlockButtonDisabled}
                scale={ButtonScale.Small}
                kind={ButtonKind.Primary}
                onClick={showModal}
            >
                {trls[TrlKeys.disableCT]}
            </Button>
            <Modal visible={isModalVisible} onClose={closeModal}>
                <ModalHeader>
                    <ModalTitle>{trls[TrlKeys.disableCT]}</ModalTitle>
                </ModalHeader>
                <ModalContent>
                    {format(trls[TrlKeys.disableCTWarning], {
                        '{0}': vacancyId,
                    })}
                </ModalContent>
                <ModalError visible={!!callTrackingDisableError}>{callTrackingDisableError}</ModalError>
                <ModalFooter>
                    <Button onClick={onDisableCallTrackingClick} kind={ButtonKind.Primary}>
                        {trls[TrlKeys.disable]}
                    </Button>
                    <FormSpacer>
                        <Button onClick={closeModal}>{trls[TrlKeys.cancel]}</Button>
                    </FormSpacer>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default translation(DisableCallTracking);
