enum Color {
    Blue50 = '#0070ff',
    Green50 = '#0dc267',

    Gray10 = '#F4F6FB',
    Gray20 = '#EEF1F7',
    Gray30 = '#CCD5DF',
    Gray40 = '#AABBCA',
    Gray60 = '#5E6C77',

    Violet40 = '#9A67EF',
    Violet60 = '#883BE9',
}

export default Color;
