// tempexp_26879_file
import { FC, PropsWithChildren, useEffect, useState } from 'react';

const SsrGuard: FC<PropsWithChildren> = ({ children }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
    }, []);

    if (!visible) {
        return null;
    }
    return <>{children}</>;
};

export default SsrGuard;
