import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import BannerWrapper from 'lux/components/VacancyBannerWrapper';
import translation from 'lux/components/translation';
import paths from 'lux/modules/routePaths';
import { useSelector } from 'lux/modules/useSelector';
import formatToInt from 'lux/utils/formatToInt';

interface SuitableVacanciesBannerProps {
    vacancyId: string;
}

const TrlKeys = {
    title: 'employer.analytics.block.suitableVacancies.title',
    text: 'employer.analytics.block.suitableVacancies.text',
};

const SuitableVacanciesBanner: TranslatedComponent<SuitableVacanciesBannerProps> = ({ vacancyId, trls }) => {
    const link = `${paths.resumeSearch}?vacancy_id=${vacancyId}&order_by=relevance&search_period=365&from=vacancy_view_banner&hhtmFromLabel=vacancy_view_banner`;
    const resumesCount = useSelector((state) => state.resumeSearchResult.totalResults);

    if (!resumesCount) {
        return null;
    }

    const similarResumesCount = String(formatToInt(resumesCount));

    const onClickLink = () => {
        Analytics.sendHHEventButtonClick('new_employer_vacancy_promo_banner_suitable_resumes_link', {
            vacancyId,
        });
    };

    return (
        <Link to={link} target="_blank" onClick={onClickLink}>
            <BannerWrapper
                dataQa="suitable-vacancies-banner"
                header={format(trls[TrlKeys.title], { '{0}': similarResumesCount })}
            >
                <>{trls[TrlKeys.text]}</>
            </BannerWrapper>
        </Link>
    );
};

export default translation(SuitableVacanciesBanner);
