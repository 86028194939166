import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useBreakpoint, Button } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { MenuItem } from 'bloko/blocks/drop';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';

import { sendEventClickAction, sendEventShownButton } from 'lux/components/VacancyActions/sendVacancyActionsAnalytics';
import useRunEmployerVacancyAction from 'lux/hooks/useRunEmployerVacancyAction';

import VacancyActionLayout from 'lux/pages/VacancyView/components/VacancyActionLayout';

const VacancyViewAction = ({
    children,
    isLoading,
    layoutCallback,
    onTrigger,
    triggerHref,
    triggerName,
    triggerType,
    buttonsContainerRef,
    sendAnaliticsRef,
    counter,
    icon,
}) => {
    const wrapperRef = useRef(null);
    const { isXS } = useBreakpoint();

    useRunEmployerVacancyAction(triggerName, () => {
        if (onTrigger) {
            onTrigger();
        }
    });

    useEffect(() => {
        if (wrapperRef.current) {
            layoutCallback(triggerName, wrapperRef);
        }
    }, [layoutCallback, triggerName]);

    useEffect(() => {
        if (!sendAnaliticsRef.current.includes(triggerName)) {
            sendEventShownButton(buttonsContainerRef, wrapperRef, triggerName);
            sendAnaliticsRef.current.push(triggerName);
        }
    }, [buttonsContainerRef, sendAnaliticsRef, triggerName, wrapperRef]);

    const onClick = () => {
        sendEventClickAction(triggerType, triggerName, !!triggerHref);
        if (onTrigger) {
            onTrigger();
        }
    };

    const targetProps = triggerName === 'print' ? { target: '_blank' } : {};

    if (triggerType === 'button') {
        const style = ['responses-link', 'prolongate'].includes(triggerName) ? 'accent' : 'neutral';
        const mode = { mode: ['responses-link'].includes(triggerName) ? 'primary' : 'secondary' };
        const stretched = isXS && triggerName === 'responses-link' ? { stretched: true } : {};
        const counterProp = counter && triggerName === 'responses-link' ? { postfix: counter } : {};
        const otherProps = { ...targetProps, ...mode, ...stretched, ...counterProp };

        return (
            <div className="vacancy-action" ref={wrapperRef}>
                <VacancyActionLayout>
                    <Button
                        Element={triggerHref ? Link : undefined}
                        data-qa={`vacancies-dashboard-${triggerName}`}
                        style={style}
                        loading={
                            isLoading ? <Loading initial={LoadingColor.Gray80} scale={LoadingScale.Small} /> : null
                        }
                        onClick={onClick}
                        to={triggerHref}
                        {...otherProps}
                        icon={icon}
                    >
                        {children}
                    </Button>
                </VacancyActionLayout>
            </div>
        );
    }

    if (triggerType === 'menu') {
        return (
            <MenuItem
                data-qa={`vacancy-action-${triggerName}`}
                href={triggerHref}
                to={triggerHref}
                onClick={onClick}
                Element={triggerHref ? Link : undefined}
                {...targetProps}
            >
                <VacancyActionLayout isMenu>
                    {children}
                    {icon}
                </VacancyActionLayout>
            </MenuItem>
        );
    }

    return null;
};

VacancyViewAction.propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    layoutCallback: PropTypes.func,
    sendAnaliticsRef: PropTypes.object,
    onTrigger: PropTypes.func,
    triggerHref: PropTypes.string,
    triggerName: PropTypes.string,
    triggerType: PropTypes.string,
    buttonsContainerRef: PropTypes.object,
    icon: PropTypes.element,
    counter: PropTypes.string,
};

export default VacancyViewAction;
