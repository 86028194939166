import { Fragment, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Column from 'bloko/blocks/column';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { useNotification } from 'lux/components/Notifications/Provider';
import RelatedEmployerVacancies from 'lux/components/RelatedEmployerVacancies';
import RelatedVacancies from 'lux/components/RelatedVacancies';
import RelatedVacanciesLazy from 'lux/components/RelatedVacancies/LazyWrapper';
import VacancyResponsePopup from 'lux/components/VacancyResponsePopup';
import CountriesProfileVisibilityAgreementPopup from 'lux/components/VacancyResponsePopup/CountriesProfileVisibilityAgreementPopup';
import RelocationWarningPopup from 'lux/components/VacancyResponsePopup/RelocationWarningPopup';
import VacancyDefaultContainer from 'lux/components/VacancyView/DefaultContainer';
import VacancyBrandedBody from 'lux/components/VacancyView/VacancyBrandedBody';
import VacancyViewPropTypes from 'lux/components/VacancyView/propTypes';
import useFeatureEnabled from 'lux/hooks/useFeatureEnabled';
import { useSelector } from 'lux/modules/useSelector';

import vacancyViewsCountNotification from 'lux/pages/VacancyView/components/VacancyViewsCountNotification';
import useVacancyViewsCountNotification from 'lux/pages/VacancyView/components/useVacancyViewsCountNotification';

const Features = {
    enableVacancyPerformance: 'enable_vacancy_performance',
};

const VacancyViewConstructor = ({ vacancyView }) => {
    const vacancyViewsCount = useSelector((state) => state.vacancyViewsCount);
    const isVacancySpeedupExp = useSelector((state) => state.vacancyPageSpeedup);
    const { addNotification } = useNotification();
    const dispatch = useDispatch();
    const relatedVacanciesRef = useRef();
    const contactsNodeRef = useRef();
    const vacancyBodyFooterNodeRef = useRef();

    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;

    const enableVacancyPerformance = useFeatureEnabled(Features.enableVacancyPerformance) || isVacancySpeedupExp;
    useVacancyViewsCountNotification(enableVacancyPerformance);

    useEffect(() => {
        if (!enableVacancyPerformance && vacancyViewsCount && !vacancyView.responseInfo?.doResponse) {
            addNotification(vacancyViewsCountNotification);
        }
    }, [addNotification, dispatch, enableVacancyPerformance, vacancyView.responseInfo, vacancyViewsCount]);

    return (
        <Fragment>
            <VacancyBrandedBody
                contactsNodeRef={contactsNodeRef}
                isRedesign
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                hasTopPadding
            />
            <VacancyDefaultContainer>
                <div className="noprint">
                    <Column xs="4" s="8" m="8" l="10">
                        {isVacancySpeedupExp ? <RelatedVacanciesLazy forEmployer /> : <RelatedEmployerVacancies />}
                    </Column>
                    <Column xs="4" s="8" m="8" l="10">
                        {isVacancySpeedupExp ? (
                            <RelatedVacanciesLazy blockRef={relatedVacanciesRef} />
                        ) : (
                            <RelatedVacancies blockRef={relatedVacanciesRef} />
                        )}
                    </Column>
                </div>
            </VacancyDefaultContainer>
            <RelocationWarningPopup vacancyId={vacancyView.vacancyId} />
            <CountriesProfileVisibilityAgreementPopup vacancyId={vacancyView.vacancyId} />
            <VacancyResponsePopup
                vacancyId={vacancyView.vacancyId}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                isXs={isXs}
            />
        </Fragment>
    );
};

VacancyViewConstructor.propTypes = {
    vacancyView: VacancyViewPropTypes.vacancyView,
};

export default VacancyViewConstructor;
