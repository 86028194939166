import { Fragment, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Column from 'bloko/blocks/column';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { useNotification } from 'lux/components/Notifications/Provider';
import RelatedEmployerVacancies from 'lux/components/RelatedEmployerVacancies';
import RelatedVacancies from 'lux/components/RelatedVacancies';
import RelatedVacanciesLazy from 'lux/components/RelatedVacancies/LazyWrapper';
import VacancyResponsePopup from 'lux/components/VacancyResponsePopup';
import CountriesProfileVisibilityAgreementPopup from 'lux/components/VacancyResponsePopup/CountriesProfileVisibilityAgreementPopup';
import RelocationWarningPopup from 'lux/components/VacancyResponsePopup/RelocationWarningPopup';
import VacancyDefaultContainer from 'lux/components/VacancyView/DefaultContainer';
import VacancyBodyRedesigned from 'lux/components/VacancyView/VacancyBodyRedesigned';
import VacancyBrandedBody from 'lux/components/VacancyView/VacancyBrandedBody';
import useBranding from 'lux/components/VacancyView/hooks/useBranding';
import VacancyViewPropTypes from 'lux/components/VacancyView/propTypes';
import useShowResponse from 'lux/components/VacancyView/useShowResponse';
import useFeatureEnabled from 'lux/hooks/useFeatureEnabled';
import { useSelector } from 'lux/modules/useSelector';
import { addTabListener, removeTabListener } from 'lux/utils/viewDuration';

import VacancySidebar from 'lux/pages/VacancyView/components/VacancySidebar';
import vacancyViewsCountNotification from 'lux/pages/VacancyView/components/VacancyViewsCountNotification';
import useVacancyViewsCountNotification from 'lux/pages/VacancyView/components/useVacancyViewsCountNotification';

const Features = {
    enableVacancyPerformance: 'enable_vacancy_performance',
};

const VacancyView = ({ vacancyView }) => {
    const vacancyViewsCount = useSelector((state) => state.vacancyViewsCount);
    const isVacancySpeedupExp = useSelector((state) => state.vacancyPageSpeedup);
    const viewDuration = useSelector((state) => state.viewDuration);
    const { addNotification } = useNotification();
    const dispatch = useDispatch();
    const relatedVacanciesRef = useRef();
    const contactsNodeRef = useRef();
    const vacancyBodyFooterNodeRef = useRef();
    const { isBranded } = useBranding();
    const enableVacancyPerformance = useFeatureEnabled(Features.enableVacancyPerformance) || isVacancySpeedupExp;
    useShowResponse();
    useVacancyViewsCountNotification(enableVacancyPerformance);

    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;

    useEffect(() => {
        if (!enableVacancyPerformance && vacancyViewsCount && !vacancyView.responseInfo?.doResponse) {
            addNotification(vacancyViewsCountNotification);
        }
    }, [enableVacancyPerformance, addNotification, dispatch, vacancyView.responseInfo, vacancyViewsCount]);

    useEffect(() => {
        addTabListener(viewDuration);
        return () => {
            removeTabListener();
        };
    }, [viewDuration]);

    const relatedVacanciesBlock = (
        <div className="noprint">
            <Column xs="4" s="8" m="12" l="10">
                {isVacancySpeedupExp ? <RelatedVacanciesLazy forEmployer /> : <RelatedEmployerVacancies />}
            </Column>
            <Column xs="4" s="8" m="12" l="10">
                {isVacancySpeedupExp ? (
                    <RelatedVacanciesLazy blockRef={relatedVacanciesRef} />
                ) : (
                    <RelatedVacancies blockRef={relatedVacanciesRef} />
                )}
            </Column>
        </div>
    );

    return (
        <Fragment>
            {isBranded ? (
                <VacancyBrandedBody
                    contactsNodeRef={contactsNodeRef}
                    vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                    bottomContent={relatedVacanciesBlock}
                    isRedesign
                />
            ) : (
                <VacancyDefaultContainer
                    isRedesign
                    rightColumn={
                        <Column xs="0" s="0" m="0" l="6" container>
                            <VacancySidebar
                                fullListRef={relatedVacanciesRef}
                                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                            />
                        </Column>
                    }
                >
                    <VacancyBodyRedesigned
                        contactsNodeRef={contactsNodeRef}
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                    />
                    {relatedVacanciesBlock}
                </VacancyDefaultContainer>
            )}
            <RelocationWarningPopup vacancyId={vacancyView.vacancyId} />
            <CountriesProfileVisibilityAgreementPopup vacancyId={vacancyView.vacancyId} />
            <VacancyResponsePopup
                vacancyId={vacancyView.vacancyId}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                isXs={isXs}
            />
        </Fragment>
    );
};

VacancyView.propTypes = {
    vacancyView: VacancyViewPropTypes.vacancyView,
};

export default VacancyView;
