import { FC, useEffect } from 'react';

import { useNotification } from 'lux/components/Notifications/Provider';
import useShowRisePage from 'lux/components/Rise/useShowRisePage';
import { useSelector } from 'lux/modules/useSelector';

import SnackbarRisePageExperimentNotification from 'lux/components/VacancyActions/SnackbarRisePageExperimentNotification';

const SnackbarRisePageExperiment: FC = () => {
    const { isAnyRisePageExperimentEnabled } = useShowRisePage();
    const error = useSelector((state) => state.employerVacanciesRise?.error);
    const success = useSelector((state) => state.employerVacanciesRise?.success);
    const { addNotification } = useNotification();

    useEffect(() => {
        if (!isAnyRisePageExperimentEnabled || (!success && !error)) {
            return;
        }
        addNotification(SnackbarRisePageExperimentNotification);
    }, [addNotification, error, isAnyRisePageExperimentEnabled, success]);

    return null;
};

export default SnackbarRisePageExperiment;
