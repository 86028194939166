import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { useNotification } from 'lux/components/Notifications/Provider';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';

import vacancyViewsCount from 'lux/pages/VacancyView/components/VacancyViewsCountNotification';

const vacancyViewsCountAction = makeSetStoreField('vacancyViewsCount');
const useVacancyViewsCountNotification = (enableVacancyPerformance) => {
    const vacancy = useSelector(({ vacancyView }) => vacancyView);
    const { addNotification } = useNotification();
    const abortController = useRef(null);
    const dispatch = useDispatch();
    const vacancyId = vacancy.vacancyId;
    const responseStatus = useSelector(
        ({ applicantVacancyResponseStatuses }) => applicantVacancyResponseStatuses[vacancyId]
    );

    const showNotification =
        enableVacancyPerformance &&
        responseStatus?.usedResumeIds?.length === 0 &&
        !vacancy?.showResumeForm &&
        !vacancy?.responseInfo?.doResponse;

    useEffect(() => {
        if (showNotification) {
            abortController.current = new AbortController();
            fetcher
                .get('/shards/vacancy_view_count', {
                    params: { vacancyId },
                    signal: abortController.current.signal,
                })
                .then((data) => {
                    if (data?.vacancyViewCount) {
                        dispatch(vacancyViewsCountAction(data?.vacancyViewCount));
                        addNotification(vacancyViewsCount);
                    }
                })
                .catch(console.error);
        }

        return () => {
            abortController.current?.abort();
        };
    }, [addNotification, dispatch, showNotification, vacancyId]);
};

export default useVacancyViewsCountNotification;
