import { useCallback, ReactNode, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SegmentedControl from 'lux/components/SegmentedControl';
import VacancyBody from 'lux/components/VacancyView/VacancyBody';
import VacancyBrandedBody from 'lux/components/VacancyView/VacancyBrandedBody';
import useBranding from 'lux/components/VacancyView/hooks/useBranding';
import translation from 'lux/components/translation';
import TabPane from 'lux/pages/VacancyView/components/VacancyTabs/TabPane';

import SearchSnippet from 'lux/pages/VacancyView/components/VacancyMainTab/SearchSnippet';
import { SegmentSize, SegmentPane } from 'lux/pages/VacancyView/components/VacancyMainTab/SearchSnippet/constants';
import useSnippetData from 'lux/pages/VacancyView/components/VacancyMainTab/useSnippetData';
import useVacancySearchSnippet from 'lux/pages/VacancyView/components/VacancyMainTab/useVacancySearchSnippet';

interface VacancyMainTabProps {
    employerReviews: ReactNode;
    backofficeRelatedVacancies: ReactNode;
}

const TrlKeys = {
    segment: {
        main: 'employer.vacancy.segment.main.title',
        snippet: 'employer.vacancy.segment.snippet.title',
    },
};

const VacancyMainTab: TranslatedComponent<VacancyMainTabProps> = ({
    trls,
    employerReviews,
    backofficeRelatedVacancies,
}) => {
    const { isBranded } = useBranding();
    const [activePane, setActivePane] = useState(SegmentPane.Main);
    const vacancySearchSnippet = useVacancySearchSnippet();
    const showSearchSnippet = !!vacancySearchSnippet;
    const [resetSnippet, fetchSnippet] = useSnippetData();

    const segmentSection = {
        [SegmentPane.Main]: trls[TrlKeys.segment.main],
        [SegmentPane.Snippet]: trls[TrlKeys.segment.snippet],
    };

    const CustomColumnsWrapper = useCallback(({ children }: { children: ReactNode }) => <>{children}</>, []);
    const AdditionalWrapper = useCallback(
        ({ children }: { children: ReactNode }) => (
            <div className="vacancy-branded-employer-additional-wrapper">{children}</div>
        ),
        []
    );
    const DescriptionWrapper = useCallback(
        ({ children }: { children: ReactNode }) => (
            <div className="vacancy-branded-employer-description-wrapper">{children}</div>
        ),
        []
    );

    const onSelectSnippetPane = (pane: SegmentSize) => {
        if (pane === SegmentSize.Full) {
            void fetchSnippet();
        } else {
            resetSnippet();
        }
    };

    const onSelect = (pane: SegmentPane) => {
        if (pane === SegmentPane.Main) {
            resetSnippet();
        }
        setActivePane(pane);
        Analytics.sendHHEventButtonClick(`vacancy_view_${pane}_segment_select`, {
            vacancyId: vacancySearchSnippet?.vacancyId,
        });
    };

    const onSnownSegment = (element: HTMLDivElement) => {
        if (element) {
            Analytics.sendHHEventElementShown(element, {
                name: 'vacancy_view_segment_control',
                vacancyId: vacancySearchSnippet?.vacancyId,
            });
        }
    };

    return (
        <>
            {showSearchSnippet && (
                <ColumnsRow>
                    <Column xs="4" s="8" m="8" l="10">
                        <div ref={onSnownSegment}>
                            <SegmentedControl
                                active={activePane}
                                sections={segmentSection}
                                onSelect={onSelect}
                                size="medium"
                            />
                        </div>
                        <VSpacing default={24} />
                    </Column>
                </ColumnsRow>
            )}
            <TabPane index={SegmentPane.Main} value={activePane}>
                {isBranded ? (
                    <VacancyBrandedBody
                        CustomColumnsWrapper={CustomColumnsWrapper}
                        AdditionalWrapper={AdditionalWrapper}
                        DescriptionWrapper={DescriptionWrapper}
                        isEmployerView
                        newEmployerVacancyMap
                    />
                ) : (
                    <>
                        <VacancyBody newEmployerVacancyMap />
                        {employerReviews}
                        {backofficeRelatedVacancies}
                    </>
                )}
            </TabPane>
            {showSearchSnippet && (
                <TabPane index={SegmentPane.Snippet} value={activePane} destroyInactive>
                    <ColumnsRow>
                        <Column xs="4" s="8" m="8" l="10">
                            <SearchSnippet vacancy={vacancySearchSnippet} onSelectPane={onSelectSnippetPane} />
                            <VSpacing default={24} />
                        </Column>
                    </ColumnsRow>
                </TabPane>
            )}
        </>
    );
};

export default translation(VacancyMainTab);
