import React, { FC, useContext, useEffect, useRef } from 'react';
import { select } from 'd3-selection';

import ChartContext from 'lux/pages/VacancyView/components/VacancyStatTab/Chart/LineChart/ChartContext';
import Color from 'lux/pages/VacancyView/components/VacancyStatTab/Chart/color';

import styles from './line.less';

interface LineProps {
    color: Color;
    gradient?: boolean;
    showPoints?: boolean;
    showPointsLabel?: boolean;
}

const Line: FC<LineProps> = ({ color, gradient, showPoints = false, showPointsLabel = false }) => {
    const { linePath, areaPath, data, xAxis, yAxis } = useContext(ChartContext).chartData;
    const { width, height, chartDimension } = useContext(ChartContext);
    const lineChartRef = useRef<SVGGElement | null>(null);

    useEffect(() => {
        if (!lineChartRef.current) {
            return;
        }

        const filtered = data.filter((p, ind) => !(ind % chartDimension) && !p.additional?.isFake);
        const points = select(lineChartRef.current).selectAll('circle').data(filtered).enter();

        if (showPoints) {
            points
                .append('circle')
                .attr('fill', color)
                .attr('stroke', 'none')
                .attr('cx', (d) => xAxis(d.date))
                .attr('cy', (d) => yAxis(d.value))
                .attr('r', 4);
        }

        if (showPointsLabel) {
            points
                .append('text')
                .attr('x', (d) => xAxis(d.date))
                .attr('y', (d) => yAxis(d.value))
                .text((d) => d.value)
                .attr('class', styles.lineChartPointText);
        }

        // eslint-disable-next-line consistent-return
        return () => {
            points.selectAll('circle').remove();
            points.selectAll('text').remove();
        };
    }, [color, data, xAxis, yAxis, width, height, showPoints, showPointsLabel, chartDimension]);

    return (
        <g ref={lineChartRef}>
            {gradient && areaPath && (
                <>
                    <linearGradient id={`linear-gradient-${color}`} x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor={color} stopOpacity="50%" />
                        <stop offset="100%" stopColor={color} stopOpacity="0%" />
                    </linearGradient>
                    <path d={areaPath} fill={`url(#linear-gradient-${color})`} />
                </>
            )}
            {linePath && <path fill="none" stroke={color} strokeWidth={1.5} d={linePath} />}
        </g>
    );
};

export default Line;
