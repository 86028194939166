import React, { FC } from 'react';

import { VacancyConversionStatistics } from 'lux/models/vacancyConversionStatistics';

import CommonCard from 'lux/pages/VacancyView/components/VacancyStatTab/Cards/CommonCard';
import ComparisonResultCard from 'lux/pages/VacancyView/components/VacancyStatTab/Cards/ComparisonResultCard';
import getComparisonQuality from 'lux/pages/VacancyView/components/VacancyStatTab/Cards/getComparisonQuality';

import styles from './cards.less';

interface CardsProps {
    data: VacancyConversionStatistics;
}

const Cards: FC<CardsProps> = ({ data }) => {
    const qualityResult = getComparisonQuality(data.viewToResponseConversionComparison);

    return (
        <div className={styles.cardsWrap}>
            <CommonCard data={data} qualityResult={qualityResult} />
            {qualityResult !== 'noCalc' && <ComparisonResultCard data={data} qualityResult={qualityResult} />}
        </div>
    );
};

export default Cards;
