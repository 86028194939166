import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import styles from './vacancy-action-layout.less';

interface VacancyActionLayoutProps {
    children: ReactNode;
    isMenu?: boolean;
}

const VacancyActionLayout: FC<VacancyActionLayoutProps> = ({ children, isMenu = false }) => {
    return (
        <div className={classnames(styles.vacancyActionLayout, { [styles.vacancyActionLayoutMenuItem]: isMenu })}>
            {children}
        </div>
    );
};

export default VacancyActionLayout;
