import React, { useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Card } from '@hh.ru/magritte-ui';
import Link from '@hh.ru/redux-spa-middleware/lib/Link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyConversionInfo from 'lux/components/VacancyConversionInfo';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import styles from './vacancy-important-banners.less';

interface ConversionBannerProps {
    vacancyId: string;
}

const ConversionBanner: TranslatedComponent<ConversionBannerProps> = ({ vacancyId }) => {
    const conversion = useSelector(({ vacancyAnalyticsConversion }) => vacancyAnalyticsConversion);

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (ref.current) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'important_banner_conversion',
                vacancyId,
            });
        }
    }, [vacancyId]);

    if (!conversion) {
        return null;
    }

    return (
        <Link
            to={`/employer/statistics/vacancy_conversion?vacancyId=${String(vacancyId)}`}
            target="_blank"
            onClick={() => {
                Analytics.sendHHEventButtonClick('important_banner_conversion_button', {
                    vacancyId,
                });
            }}
        >
            <Card
                borderRadius={12}
                padding={12}
                actionCard
                stretched
                showShadowOnHover
                data-qa="vacancy-important-banner-conversion"
            >
                <div className={styles.banner} ref={ref}>
                    <VacancyConversionInfo conversion={conversion} />
                </div>
            </Card>
        </Link>
    );
};

export default translation(ConversionBanner);
