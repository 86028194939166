import classnames from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { AuctionCampaignStatus } from 'lux/models/auctionData';
import { useSelector } from 'lux/modules/useSelector';

import styles from './campaign-status-text.less';

const TrlKeys = {
    [AuctionCampaignStatus.AccountLowBalance]: 'label.no.money',
    [AuctionCampaignStatus.Active]: 'label.active',
    [AuctionCampaignStatus.Blocked]: 'label.pause',
    [AuctionCampaignStatus.CampaignLowBalance]: 'label.limitReached',
};

interface CampaignStatusTextProps {
    dataQaPrefix: string;
}

const CampaignStatusText: TranslatedComponent<CampaignStatusTextProps> = ({ dataQaPrefix, trls }) => {
    const campaignStatus = useSelector(({ auctionData }) => auctionData?.currentVacancyAuctionCampaign?.campaignStatus);

    if (!campaignStatus) {
        return null;
    }

    return (
        <div
            data-qa={`${dataQaPrefix}-campaign-status-${campaignStatus}`}
            className={classnames(styles.text, {
                [styles.textAccountLowBalance]: campaignStatus === AuctionCampaignStatus.AccountLowBalance,
                [styles.textActive]: campaignStatus === AuctionCampaignStatus.Active,
                [styles.textBlocked]: campaignStatus === AuctionCampaignStatus.Blocked,
                [styles.textCampaignLowBalance]: campaignStatus === AuctionCampaignStatus.CampaignLowBalance,
            })}
        >
            {trls[TrlKeys[campaignStatus]]}
        </div>
    );
};

export default translation(CampaignStatusText);
