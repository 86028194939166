import { FC } from 'react';
import classnames from 'classnames';

import { VacancyComparisonQuality } from 'lux/pages/VacancyView/components/VacancyStatTab/Cards/types';

import styles from './quality-conversion-text.less';

interface QualityConversionTextProps {
    quality: VacancyComparisonQuality;
    text: string;
}

const QualityConversionText: FC<QualityConversionTextProps> = ({ quality, text }) => {
    return (
        <strong
            className={classnames({
                [styles.qualityLow]: quality === 'low',
                [styles.qualityMid]: quality === 'mid',
                [styles.qualityHigh]: quality === 'high',
                [styles.qualityNoCalc]: quality === 'noCalc',
            })}
        >
            {text}
        </strong>
    );
};

export default QualityConversionText;
