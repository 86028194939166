import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useChartColor } from 'lux/components/VacancyView/SalaryMarketRangeChart/utils';
import translation from 'lux/components/translation';

import styles from './chart-legend-info.less';

const TrlKeys = {
    text: 'vacancy.salaryMarketRangeChart.legend.text',
};

const ChartLegendInfo: TranslatedComponent = ({ trls }) => {
    const color = useChartColor();

    return (
        <div className={styles.chartLegendInfo}>
            <div className={styles.chartLegendInfoCircle} style={{ backgroundColor: color }} />
            <Text typography="label-2-regular">{trls[TrlKeys.text]}</Text>
        </div>
    );
};

export default translation(ChartLegendInfo);
