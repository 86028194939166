import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { AsyncHighlighterContext, getAsyncHighlighter } from 'lux/components/Markup/AsyncHighlighterModule';
import PageLayout, { EmptyLayout } from 'lux/components/PageLayout';
import SnackbarRisePageExperiment from 'lux/components/VacancyActions/SnackbarRisePageExperiment';
import useBranding from 'lux/components/VacancyView/hooks/useBranding';
import translation from 'lux/components/translation';
import { UserType } from 'lux/models/userType';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';

import VacancyView from 'lux/pages/VacancyView/VacancyView';
import VacancyViewConstructor from 'lux/pages/VacancyView/VacancyViewConstructor';
import VacancyViewEmployer from 'lux/pages/VacancyView/VacancyViewEmployer';
import useVacancyViewMeta from 'lux/pages/VacancyView/useVacancyViewMeta';

const bannersBatchUrlAction = makeSetStoreField('bannersBatchUrl');

const getTranslations = (trls, trlsMapping) => {
    return Object.keys(trlsMapping).reduce((acc, key) => {
        acc[key] =
            typeof trlsMapping[key] === 'object' ? getTranslations(trls, trlsMapping[key]) : trls[trlsMapping[key]];
        return acc;
    }, {});
};

const VacancyViewRoute = ({ trls }) => {
    const searchQuery = useSelector((state) => state.searchQuery);
    const vacancyView = useSelector((state) => state.vacancyView);
    const userType = useSelector((state) => state.userType);
    const { isConstructor } = useBranding();
    const pageMeta = useVacancyViewMeta({ trls: getTranslations(trls, VacancyViewRoute.trls) });
    const isVacancySpeedupExp = useSelector((state) => state.vacancyPageSpeedup);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isVacancySpeedupExp) {
            return undefined;
        }
        const abortController = new AbortController();
        fetcher
            .get('/shards/banners/targeting_params', {
                params: {
                    currencyCode: vacancyView.compensation.currencyCode,
                    salary: vacancyView.compensation.from,
                    area: vacancyView.area['@regionId'],
                    roles: vacancyView.professionalRoleIds,
                },
                signal: abortController.signal,
            })
            .then((data) => {
                window.globalVars.bannersBatchUrl = data.bannersBatchUrl;
                dispatch(bannersBatchUrlAction(data.bannersBatchUrl));
            })
            .catch((e) => {
                console.error(e);
            });
        return () => {
            abortController.abort();
        };
    }, [dispatch, isVacancySpeedupExp, vacancyView.area, vacancyView.compensation, vacancyView.professionalRoleIds]);

    let vacancyPageContent = null;
    if ([UserType.Employer, UserType.BackOffice].includes(userType)) {
        vacancyPageContent = <VacancyViewEmployer vacancyView={vacancyView} />;
    } else if (isConstructor) {
        vacancyPageContent = <VacancyViewConstructor vacancyView={vacancyView} />;
    } else {
        vacancyPageContent = <VacancyView vacancyView={vacancyView} />;
    }

    return (
        <AsyncHighlighterContext.Provider value={getAsyncHighlighter(searchQuery)}>
            <PageLayout
                title={pageMeta.title}
                ogTitle={pageMeta.ogTitle}
                description={pageMeta.description}
                ogDescription={pageMeta.ogDescription}
                keywords={pageMeta.keywords}
                layout={EmptyLayout}
            >
                {vacancyPageContent}
                <SnackbarRisePageExperiment />
            </PageLayout>
        </AsyncHighlighterContext.Provider>
    );
};

VacancyViewRoute.trls = {
    pageTitle: 'vacancyview.title',
    jobIn: 'vacancyview.title.jobin',
    archived: 'vacancies.title.archived',
    archivedFrom: 'vacancies.title.archived.from',
    inCompany: 'title.incompany',
    titleCompensation: 'vacancyview.titleCompensation',
    noCompensation: 'vacancyview.titleNoCompensation',
    compensationFrom: 'vacancyview.titleCompensationFrom',
    compensationTo: 'vacancyview.titleCompensationTo',
    workExperience: 'vacancyview.workExperience',
    publicationDate: 'vacancyview.publicationDate',
    month: {
        0: 'calendar.genitive.month.0',
        1: 'calendar.genitive.month.1',
        2: 'calendar.genitive.month.2',
        3: 'calendar.genitive.month.3',
        4: 'calendar.genitive.month.4',
        5: 'calendar.genitive.month.5',
        6: 'calendar.genitive.month.6',
        7: 'calendar.genitive.month.7',
        8: 'calendar.genitive.month.8',
        9: 'calendar.genitive.month.9',
        10: 'calendar.genitive.month.10',
        11: 'calendar.genitive.month.11',
    },
    currencies: {
        AZN: 'AZN',
        BYR: 'BYR',
        EUR: 'EUR',
        KZT: 'KZT',
        RUR: 'RUR',
        UAH: 'UAH',
        USD: 'USD',
        UZS: 'UZS',
        GEL: 'GEL',
        KGS: 'KGS',
    },
    zp: {
        title: 'vacancyview.zp.title',
        ogTitle: 'vacancyview.zp.ogTitle',
        description: 'vacancyview.zp.description',
        ogDescription: 'vacancyview.zp.ogDescription',
    },
};

VacancyViewRoute.propTypes = {
    trls: PropTypes.object,
};

export default translation(VacancyViewRoute);
