import { Fragment, useCallback, useMemo, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import { Badge, useBreakpoint } from '@hh.ru/magritte-ui';
import {
    ArrowPathClockwiseSize24,
    ArrowUpOutlinedSize24,
    TrashOutlinedSize24,
    WalletOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import Vspacing from 'bloko/blocks/vSpacing';
import urlParser from 'bloko/common/urlParser';

import AbstractControls from 'lux/components/AbstractControls';
import { ControlsTriggerActionType } from 'lux/components/ControlsTrigger';
import VacancyTrigger from 'lux/components/EmployerVacancies/VacancyTrigger';
// tempexp_32165_start
import ExperimentCheckOnElementShownRiseStandardSale, {
    useRiseStandardSale,
} from 'lux/components/ExperimentCheckOnElementShownRiseStandardSale';
// tempexp_32165_end
import ArchiveAction from 'lux/components/VacancyActions/ArchiveAction';
import AutoProlongationAction from 'lux/components/VacancyActions/AutoProlongationAction';
import DeleteAction from 'lux/components/VacancyActions/DeleteAction';
import DuplicateAction from 'lux/components/VacancyActions/DuplicateAction';
import EditAction from 'lux/components/VacancyActions/EditAction';
import HideAction from 'lux/components/VacancyActions/HideAction';
import OptionAction from 'lux/components/VacancyActions/OptionAction';
import PfpTopupAction from 'lux/components/VacancyActions/PfpTopupAction';
import PrintAction from 'lux/components/VacancyActions/PrintAction';
import ProlongateAction from 'lux/components/VacancyActions/ProlongateAction';
import ResponsesLinkAction from 'lux/components/VacancyActions/ResponsesLinkAction';
import RestoreAction from 'lux/components/VacancyActions/RestoreAction';
import RestoreFastAction from 'lux/components/VacancyActions/RestoreFastAction';
import RestoreToArchiveAction from 'lux/components/VacancyActions/RestoreToArchiveAction';
import UpgradeAction from 'lux/components/VacancyActions/UpgradeAction';
import VideoInterviewAction from 'lux/components/VacancyActions/VideoInterviewAction';
import { sendEventShownMenuItems } from 'lux/components/VacancyActions/sendVacancyActionsAnalytics';
import useAdaptiveVacancyActions from 'lux/components/VacancyActions/useAdaptiveVacancyActions';
import VideoInterviewInfo from 'lux/components/VacancyView/VideoInterviewInfo';
import VacancyViewPropTypes from 'lux/components/VacancyView/propTypes';
import { VacancyProperty } from 'lux/models/vacancyProperties';
import { useSelector } from 'lux/modules/useSelector';

import VacancyAction from 'lux/pages/VacancyView/components/VacancyAction';

// tempexp_32165_next_line
import styles from './index.less';

const VacancyEmployerActions = ({ checkedVacancies, dispatchModal, vacancyView, isBackoffice }) => {
    const availableVacancyActions = useSelector((state) => state.availableVacancyActions);
    const hasVideoInterview = useSelector((state) => state.hasVideoInterview);
    // tempexp_32165_next_line
    const discountInPercent = useSelector(({ riseStandardSaleVacancy }) => riseStandardSaleVacancy.discountInPercent);
    const freeRestoreDays = useSelector((state) => state.vacancyInternalInfo?.freeRestoreDays);
    const metallicId = useSelector(({ vacancyView }) => vacancyView.metallicId);
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const sendAnaliticsRef = useRef([]);

    const dispatch = useDispatch();
    const [menuItems, setMenuItems] = useState([]);
    const { isXS } = useBreakpoint();

    const [containerRef, layoutCallback] = useAdaptiveVacancyActions(setMenuItems);
    const buttonsContainerRef = useRef(null);
    // tempexp_32165_start
    const { isExpRiseStandardSaleVacancy } = useRiseStandardSale();
    const badgeRef = useRef(null);
    // tempexp_32165_end

    const afterSuccessArchiveModal = useCallback(
        (params) => {
            const feedbackNotificationParam = params.isFeedbackSent ? '&feedbackSent=true' : '';
            const query = params.responseQuery;

            if (query) {
                dispatch(push(`${location.pathname}${query}${feedbackNotificationParam}`));
            } else {
                window.location.reload();
            }
        },
        [dispatch]
    );

    const controls = useMemo(
        () => [
            {
                name: 'responses-link',
                condition: vacancyView.canViewResponses && !isXS,
                Component: ResponsesLinkAction,
            },
            {
                name: 'update',
                condition: availableVacancyActions.update,
                Component: ProlongateAction,
                additionalProps: {
                    afterSuccessAction: ({ queryParams }) => {
                        const url = urlParser(location.pathname);
                        Object.entries(queryParams).map(([key, value]) => (url.params[key] = value));
                        dispatch(push(url.href));
                    },
                    freeRenewalParameters:
                        vacancyView.vacancyProperties?.converted?.formattedProperties[VacancyProperty.ZpManualRenewal]
                            ?.parameters,
                    vacanciesInfoToProlongate: [
                        {
                            params: {
                                prolongationType: metallicId.toUpperCase(),
                            },
                            vacancyId,
                        },
                    ],
                    icon: <ArrowUpOutlinedSize24 initial="accent" />,
                },
            },
            {
                name: 'option-toggle',
                condition: availableVacancyActions.toggleOption,
                Component: OptionAction,
                additionalProps: {
                    hasActiveOption: vacancyView.vacancyProperties?.converted?.shortcut?.hasOptionPremium,
                },
            },
            {
                name: 'edit',
                condition: availableVacancyActions.edit,
                Component: EditAction,
                additionalProps: {
                    iconOnly: isXS && !menuItems.includes('edit'),
                    ...(isBackoffice && { employerId: vacancyView.company.id }),
                },
            },
            {
                name: 'archive',
                condition: availableVacancyActions.archive,
                Component: ArchiveAction,
                additionalProps: {
                    vacancyName: vacancyView.name,
                    onArchiveSuccess: afterSuccessArchiveModal,
                },
                actionType: ControlsTriggerActionType.NEGATIVE,
            },
            {
                name: 'auto-prolongation-toggle',
                condition:
                    availableVacancyActions.enableAutoProlongation || availableVacancyActions.disableAutoProlongation,
                Component: AutoProlongationAction,
                additionalProps: {
                    managerIds: [vacancyView.managerId],
                    isAutoProlongationPlanned: availableVacancyActions.disableAutoProlongation,
                    additionalAnalyticsParams: {
                        vacancy_id: vacancyView.vacancyId, // eslint-disable-line camelcase
                    },
                },
            },
            {
                name: 'pfp-topup',
                condition: !!vacancyView.vacancyProperties?.converted?.shortcut?.isPayForPerformance,
                Component: PfpTopupAction,
                additionalProps: {
                    hhtmFromLabel: 'vacancy-controls',
                    icon: <WalletOutlinedSize24 />,
                },
            },
            {
                name: 'upgrade',
                condition: availableVacancyActions.upgrade,
                Component: UpgradeAction,
                additionalProps: {
                    employerId: vacancyView.company.id,
                    from: 'from-vacancy',
                },
            },
            {
                name: 'duplicate',
                condition: availableVacancyActions.duplicate,
                Component: DuplicateAction,
            },
            {
                name: 'delete',
                condition: availableVacancyActions.delete,
                Component: DeleteAction,
                actionType: ControlsTriggerActionType.NEGATIVE,
            },
            {
                name: 'restore',
                condition: availableVacancyActions.restore,
                Component: freeRestoreDays > 0 ? RestoreFastAction : RestoreAction,
                additionalProps: {
                    iconOnly: isXS,
                    icon: <ArrowPathClockwiseSize24 />,
                },
            },
            {
                name: 'hide',
                condition: availableVacancyActions.hide,
                Component: HideAction,
                actionType: ControlsTriggerActionType.NEGATIVE,
                additionalProps: {
                    icon: <TrashOutlinedSize24 />,
                },
            },
            {
                name: 'restoreToArchive',
                condition: availableVacancyActions.restoreToArchive,
                Component: RestoreToArchiveAction,
                additionalProps: {
                    icon: <ArrowUpOutlinedSize24 />,
                },
            },
            {
                name: 'videointerview',
                condition: hasVideoInterview,
                Component: VideoInterviewAction,
                additionalProps: {
                    showInfo: !menuItems.includes('videointerview'),
                    vacanciesIds: [vacancyView.vacancyId],
                    labelEntry: 'vacancy',
                },
            },
            {
                name: 'print',
                condition: availableVacancyActions.print,
                Component: PrintAction,
                additionalProps: { vacancyId: vacancyView.vacancyId },
            },
        ],
        [
            vacancyView.canViewResponses,
            vacancyView.vacancyProperties,
            vacancyView.company.id,
            vacancyView.managerId,
            vacancyView.vacancyId,
            vacancyView.name,
            isXS,
            availableVacancyActions.update,
            availableVacancyActions.toggleOption,
            availableVacancyActions.edit,
            availableVacancyActions.enableAutoProlongation,
            availableVacancyActions.disableAutoProlongation,
            availableVacancyActions.upgrade,
            availableVacancyActions.archive,
            availableVacancyActions.duplicate,
            availableVacancyActions.delete,
            availableVacancyActions.restore,
            availableVacancyActions.hide,
            availableVacancyActions.restoreToArchive,
            availableVacancyActions.print,
            vacancyId,
            metallicId,
            isBackoffice,
            afterSuccessArchiveModal,
            freeRestoreDays,
            hasVideoInterview,
            menuItems,
            dispatch,
        ]
    );

    const ControlComponent = useMemo(
        () => (props) =>
            (
                <VacancyAction
                    buttonsContainerRef={buttonsContainerRef}
                    sendAnaliticsRef={sendAnaliticsRef}
                    layoutCallback={layoutCallback}
                    {...props}
                />
            ),
        [layoutCallback]
    );

    const controlProps = useMemo(
        () => ({
            Component: ControlComponent,
            checkedVacancies: checkedVacancies.current,
            dispatchModal,
            vacanciesIds: [vacancyView.vacancyId],
            vacancyId: vacancyView.vacancyId,
        }),
        [ControlComponent, checkedVacancies, dispatchModal, vacancyView.vacancyId]
    );

    const renderedMenuItems = useMemo(
        () => controls.filter(({ name, condition }) => condition && menuItems.includes(name)),
        [controls, menuItems]
    );

    // tempexp_32165_start
    useLayoutEffect(() => {
        if (isExpRiseStandardSaleVacancy) {
            const prolongateBtnEl = buttonsContainerRef.current.querySelector(
                '[data-qa="vacancies-dashboard-prolongate"]'
            );
            const badgeEl = badgeRef.current;
            if (prolongateBtnEl && badgeEl) {
                badgeEl.style.visibility = 'hidden';
                badgeEl.style.left = 0;
                const coordinatesProlongateBtnEl = prolongateBtnEl.getBoundingClientRect();
                const coordinatesBadgeEl = badgeEl.getBoundingClientRect();

                badgeEl.style.left = `${
                    coordinatesProlongateBtnEl.right - coordinatesBadgeEl.left - coordinatesBadgeEl.width + 10
                }px`;
                badgeEl.style.top = `-${coordinatesBadgeEl.height / 2}px`;
                badgeEl.style.visibility = 'visible';
            }
        }
    }, [isXS, isExpRiseStandardSaleVacancy]);
    // tempexp_32165_end

    return (
        <ExperimentCheckOnElementShownRiseStandardSale vacancy>
            <Fragment>
                {isXS && (
                    <>
                        <ResponsesLinkAction
                            triggerType="button"
                            {...controlProps}
                            Component={(props) => (
                                <VacancyAction
                                    buttonsContainerRef={buttonsContainerRef}
                                    sendAnaliticsRef={sendAnaliticsRef}
                                    layoutCallback={() => undefined}
                                    {...props}
                                />
                            )}
                        />
                        <Vspacing base={3} />
                    </>
                )}
                <div
                    ref={containerRef}
                    className={classnames('vacancy-actions', {
                        // tempexp_32165_next_line
                        [styles.actions]: isExpRiseStandardSaleVacancy,
                    })}
                >
                    <div className="vacancy-actions__main" ref={buttonsContainerRef}>
                        {controls.map(
                            ({ Component, name, condition, additionalProps, icon }) =>
                                condition && (
                                    <Component
                                        key={name}
                                        triggerType="button"
                                        icon={icon}
                                        {...controlProps}
                                        {...additionalProps}
                                    />
                                )
                        )}
                    </div>
                    {/* tempexp_32165_start*/}
                    {isExpRiseStandardSaleVacancy ? (
                        <div className={styles.badge} ref={badgeRef}>
                            <Badge size="small">-{discountInPercent}%</Badge>
                        </div>
                    ) : null}
                    {/* tempexp_32165_end*/}
                    <div
                        className={classnames('vacancy-actions__more', {
                            'vacancy-actions__more_hidden': !menuItems.length,
                        })}
                    >
                        <VideoInterviewInfo showInfo={hasVideoInterview && menuItems.includes('videointerview')}>
                            <AbstractControls
                                controlsList={renderedMenuItems}
                                controlProps={{
                                    ...controlProps,
                                    triggerType: 'actionList',
                                    Component: VacancyTrigger,
                                }}
                                displayAsMenu
                                dataQa={{
                                    menu: 'vacancy-page-actions-menu',
                                    button: 'vacancy-page-actions-button',
                                }}
                                onMenuStateChange={(visible) => {
                                    if (visible) {
                                        sendEventShownMenuItems(menuItems);
                                    }
                                }}
                                actionListAlignment="right"
                                actionListDirection="bottom"
                            />
                        </VideoInterviewInfo>
                    </div>
                </div>
            </Fragment>
        </ExperimentCheckOnElementShownRiseStandardSale>
    );
};

VacancyEmployerActions.propTypes = {
    checkedVacancies: PropTypes.object,
    dispatchModal: PropTypes.func,
    vacancyView: VacancyViewPropTypes.vacancyView,
    isBackoffice: PropTypes.bool,
    trls: PropTypes.object,
};

export default VacancyEmployerActions;
