import { MutableRefObject, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Skeleton } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { H1Section } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'lux/components/Notifications/Provider';
import RelatedEmployerVacancies from 'lux/components/RelatedEmployerVacancies';
import RelatedVacanciesEmployerTitle from 'lux/components/RelatedEmployerVacancies/Title';
import RelatedVacancies from 'lux/components/RelatedVacancies';
import RelatedVacanciesTitle from 'lux/components/RelatedVacancies/Title';
import { RelatedVacanciesType } from 'lux/components/RelatedVacancies/relatedVacanciesTypes';
import { addUserLabelsForVacancies } from 'lux/models/userLabelsForVacancies/userLabels';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

const relatedVacanciesAction = makeSetStoreField('relatedVacancies');
const relatedVacanciesForEmployerAction = makeSetStoreField('relatedVacanciesForEmployer');

interface RelatedVacanciesLazyWrapperProps {
    blockRef: MutableRefObject<HTMLElement>;
    forEmployer?: boolean;
}

const RelatedVacanciesLazyWrapper: TranslatedComponent<RelatedVacanciesLazyWrapperProps> = ({
    blockRef,
    forEmployer,
}) => {
    const relatedVacanciesType = useSelector((state) => state.relatedVacanciesType) as RelatedVacanciesType;
    const relatedVacanciesTypeAutodetect = useSelector((state) => state.relatedVacanciesTypeAutodetect);
    const relatedVacanciesEmployerId = useSelector((state) => state.relatedVacanciesEmployerId);
    const relatedVacancies = useSelector((state) => state.relatedVacancies);
    const relatedVacanciesForEmployer = useSelector((state) => state.relatedVacanciesForEmployer);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const searchSessionId = useSelector((state) => state.searchSessionId);
    const { addNotification } = useNotification();

    useEffect(() => {
        if (forEmployer && !relatedVacanciesEmployerId) {
            return undefined;
        }
        const abortController = new AbortController();
        fetcher
            .get('/shards/vacancy/related_vacancies', {
                params: {
                    vacancyId,
                    page: 0,
                    type: relatedVacanciesType,
                    employerId: forEmployer ? relatedVacanciesEmployerId : null,
                    searchSessionId,
                },
                signal: abortController.signal,
            })
            .then((response) => {
                response.type = relatedVacanciesType;
                // relatedVacanciesTypeAutodetect - костыль, пока relatedVacancySimilar непонятно присылает тип
                if (relatedVacanciesTypeAutodetect && response.relatedVacancySimilar !== undefined) {
                    response.type = response.relatedVacancySimilar
                        ? RelatedVacanciesType.Similar
                        : RelatedVacanciesType.Suitable;
                }
                const actions = response.vacancies.map(({ vacancyId, userLabels }) =>
                    addUserLabelsForVacancies({ vacancyId, labels: userLabels })
                );
                const setRelatedAction = forEmployer
                    ? relatedVacanciesForEmployerAction(response)
                    : relatedVacanciesAction(response);
                dispatch([...actions, setRelatedAction]);
            })
            .catch((error) => {
                defaultRequestErrorHandler(error, addNotification);
            })
            .finally(() => {
                setIsLoading(false);
            });
        return () => {
            abortController.abort();
        };
    }, [
        addNotification,
        dispatch,
        forEmployer,
        relatedVacanciesEmployerId,
        relatedVacanciesType,
        relatedVacanciesTypeAutodetect,
        searchSessionId,
        vacancyId,
    ]);

    if (forEmployer && !relatedVacanciesEmployerId) {
        return null;
    }

    if (!isLoading) {
        if (forEmployer && !relatedVacanciesForEmployer.resultsFound) {
            return null;
        }
        if (!forEmployer && !relatedVacancies.resultsFound) {
            return null;
        }
    }

    const TitleComponent = forEmployer ? RelatedVacanciesEmployerTitle : RelatedVacanciesTitle;

    return (
        <>
            {isLoading && (
                <>
                    <H1Section Element="h2">
                        <TitleComponent type={relatedVacanciesType} />
                    </H1Section>
                    <VSpacing base={4} />
                    <Skeleton height={300} loading borderRadius={16} />
                    <VSpacing base={4} />
                </>
            )}
            {!isLoading && !forEmployer && <RelatedVacancies initialType={relatedVacanciesType} blockRef={blockRef} />}
            {!isLoading && forEmployer && <RelatedEmployerVacancies initialType={relatedVacanciesType} />}
        </>
    );
};

export default RelatedVacanciesLazyWrapper;
