import { MouseEvent, ReactElement, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Tooltip } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';
import TooltipOnboardingContent from 'lux/pages/VacancyView/components/TooltipOnboardingContent';

interface TooltipStatTabProps {
    children: ReactElement;
    show: boolean;
    onNext: () => void;
    onClose: () => void;
    infotipName: string;
}

const TrlKeys = {
    title: 'employer.vacancy.tab.stat.tooltip.title',
    text: 'employer.vacancy.tab.stat.tooltip.text',
    buttons: {
        next: 'contents.button.next',
    },
};

const TooltipStatTab: TranslatedComponent<TooltipStatTabProps> = ({
    trls,
    children,
    show,
    onNext,
    onClose,
    infotipName,
}) => {
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const showTip = useSelector((state) => show && state.infoTip.name === infotipName);
    const sentRef = useRef(false);
    const activatorRef = useRef<HTMLSpanElement>(null);

    const onCloseHandler = (event: MouseEvent) => {
        event.stopPropagation();
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_tip_close', { vacancyId });
        onClose();
    };

    const onNextHandler = (event: MouseEvent) => {
        event.stopPropagation();
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_tip_next', { vacancyId });
        onNext();
    };

    const onShownHandler = (element: HTMLDivElement) => {
        if (!sentRef.current) {
            Analytics.sendHHEventElementShown(element, {
                name: 'vacancy_view_stat_tab_tip',
                vacancyId,
            });
            sentRef.current = true;
        }
    };

    return (
        <>
            <span ref={activatorRef}>{children}</span>
            <Tooltip
                visible={showTip}
                activatorRef={activatorRef}
                closeByClickOutside={false}
                direction="bottom"
                alignment="center"
                guardDistance={28}
                action={trls[TrlKeys.buttons.next]}
                actionProps={{
                    onClick: onNextHandler,
                }}
                actionClose={false}
            >
                <TooltipOnboardingContent
                    title={trls[TrlKeys.title]}
                    text={trls[TrlKeys.text]}
                    onClose={onCloseHandler}
                    onShown={onShownHandler}
                />
            </Tooltip>
        </>
    );
};

export default translation(TooltipStatTab);
