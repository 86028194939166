import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';

import VacancyBlockEmployer from 'lux/components/VacancyView/VacancyBlock/Employer';
import VacancySidebarBanners from 'lux/components/VacancyView/VacancySidebarBanners';

import RelatedVacanciesPreview from 'lux/pages/VacancyView/components/RelatedVacanciesPreview';

const VacancySidebar = ({ fullListRef, vacancyBodyFooterNodeRef }) => {
    return (
        <div className="noprint">
            <VacancyBlockEmployer vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
            <div className="vacancy-sidebar-column">
                <VacancySidebarBanners />
                <Column xs="0" s="0" m="0" l="5">
                    <RelatedVacanciesPreview fullListRef={fullListRef} />
                </Column>
            </div>
        </div>
    );
};

VacancySidebar.propTypes = {
    fullListRef: PropTypes.object,
    vacancyBodyFooterNodeRef: PropTypes.node,
};

export default VacancySidebar;
