import Analytics from '@hh.ru/analytics-js';
import { Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize16, ThumbUpOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import { VacancyConversionStatistics, ComparisonResult } from 'lux/models/vacancyConversionStatistics';
import Card, { CardTitle } from 'lux/pages/VacancyView/components/VacancyStatTab/Card';
import QualityConversionText from 'lux/pages/VacancyView/components/VacancyStatTab/QualityConversionText';

import { VacancyComparisonQuality } from 'lux/pages/VacancyView/components/VacancyStatTab/Cards/types';

import styles from './comparison-result-card.less';

const TrlKeys = {
    text: 'statportal.pages.vacancyConversion.status.comparsion',
    title: 'statportal.pages.vacancyConversion.card.comparsion.title',
    status: {
        [ComparisonResult.HIGHER]: 'statportal.pages.vacancyConversion.status.comparsion.high',
        [ComparisonResult.LOWER]: 'statportal.pages.vacancyConversion.status.comparsion.low',
    },
    quality: {
        high: 'statportal.pages.vacancyConversion.comparison.quality.high',
        mid: 'statportal.pages.vacancyConversion.comparison.quality.mid',
        low: 'statportal.pages.vacancyConversion.comparison.quality.low',
    },
};

interface ComparisonResultCardProps {
    data: VacancyConversionStatistics;
    qualityResult: Exclude<VacancyComparisonQuality, 'noCalc'>;
}

const ComparisonResultCard: TranslatedComponent<ComparisonResultCardProps> = ({ data, qualityResult, trls }) => {
    const COMPETITOR_LINK = `/employer/statistics/competitor_analysis/vacancy/${data.vacancyId}?from=vacancy&activeTab=competitorsAnalysis`;

    const comparisonResult = data.viewToResponseConversionComparison?.comparisonResult ?? ComparisonResult.LOWER;
    const comparisonValue = NumberFormatter.format(String(data.viewToResponseConversionComparison?.value || 0), {
        decimalLength: 1,
    });

    const onClickCard = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_quality_card', {
            vacancyId: data.vacancyId,
        });
    };

    return (
        <Card
            clickable
            to={COMPETITOR_LINK}
            title={
                <CardTitle
                    icon={<ThumbUpOutlinedSize16 initial="secondary" />}
                    text={trls[TrlKeys.title]}
                    iconRight={<ChevronRightOutlinedSize16 initial="secondary" />}
                />
            }
            onClickLinkCallback={onClickCard}
        >
            <VSpacingContainer default={8}>
                <Text Element="strong" data-qa="vacancy-comparison-quality">
                    <QualityConversionText quality={qualityResult} text={trls[TrlKeys.quality[qualityResult]]} />
                </Text>
                <div className={styles.comparisonResultText}>
                    <Text Element="span" data-qa="comparison-result">
                        {format(trls[TrlKeys.text], {
                            '{0}': trls[TrlKeys.status[comparisonResult]],
                            '{1}': comparisonValue,
                        })}
                    </Text>
                </div>
            </VSpacingContainer>
        </Card>
    );
};

export default translation(ComparisonResultCard);
