import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

import Analytics from '@hh.ru/analytics-js';
import { Tabs, Tab, TabId, Divider } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import Notices from 'Modules/Notices';
import translation from 'lux/components/translation';
import useOnOffState from 'lux/hooks/useOnOffState';
import { VacancyViewTab, setVacancyViewActiveTab } from 'lux/models/vacancyViewTab';
import { useSelector } from 'lux/modules/useSelector';
import TooltipHistoryTab from 'lux/pages/VacancyView/components/VacancyHistoryTab/TooltipHistoryTab';
import TooltipStatTab from 'lux/pages/VacancyView/components/VacancyStatTab/TooltipStatTab';

const TrlKeys = {
    tab: {
        [VacancyViewTab.Main]: 'employer.vacancy.tab.main',
        [VacancyViewTab.Stat]: 'employer.vacancy.tab.stat',
        [VacancyViewTab.History]: 'employer.vacancy.tab.history',
    },
};

const INFOTIP_NAME = 'employerVacancyTabsOnboarding';

const VacancyTabs: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();
    const { active: activeTab, tabs } = useSelector((state) => state.vacancyViewTab);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);

    const [showStatTip, onShowStatTip, onCloseStatTip] = useOnOffState(true);
    const [showHistTip, onShowHistTip, onCloseHistTip] = useOnOffState(false);

    const onChangeTab = (tab: TabId) => {
        const newLocation = urlParser(window.location.pathname + window.location.search);

        if (tab === VacancyViewTab.Main) {
            delete newLocation.params.activeTab;
        } else {
            newLocation.params.activeTab = tab;
        }
        Analytics.sendHHEventButtonClick(`vacancy_view_${tab}_tab_select`, {
            vacancyId,
        });
        dispatch(replace(newLocation.href, { tab }, { cancelFetchingData: true }));
        dispatch(
            setVacancyViewActiveTab({
                active: tab as VacancyViewTab,
            })
        );
    };

    const nextHistTipHandler = () => {
        onCloseStatTip();
        onChangeTab(VacancyViewTab.History);
        onShowHistTip();
    };

    const backStatTipHandler = () => {
        onCloseHistTip();
        onChangeTab(VacancyViewTab.Stat);
        onShowStatTip();
    };

    const closeHistTipHandler = () => {
        onCloseHistTip();
        Notices.markAsViewed(INFOTIP_NAME);
    };

    const closeStatTipHandler = () => {
        onCloseStatTip();
        Notices.markAsViewed(INFOTIP_NAME);
    };

    return (
        <>
            <Tabs activeItemId={activeTab} mode="secondary" onChange={onChangeTab}>
                <Tab id={VacancyViewTab.Main} data-qa="tab-main">
                    <span>{trls[TrlKeys.tab.main]}</span>
                </Tab>
                <Tab id={VacancyViewTab.Stat} hidden={!tabs.includes(VacancyViewTab.Stat)} data-qa="tab-stat">
                    <TooltipStatTab
                        show={showStatTip}
                        onNext={nextHistTipHandler}
                        onClose={closeStatTipHandler}
                        infotipName={INFOTIP_NAME}
                    >
                        <span>{trls[TrlKeys.tab.stat]}</span>
                    </TooltipStatTab>
                </Tab>
                <Tab id={VacancyViewTab.History} hidden={!tabs.includes(VacancyViewTab.History)} data-qa="tab-history">
                    <TooltipHistoryTab show={showHistTip} onBack={backStatTipHandler} onClose={closeHistTipHandler}>
                        <span>{trls[TrlKeys.tab.history]}</span>
                    </TooltipHistoryTab>
                </Tab>
            </Tabs>
            <Divider />
        </>
    );
};

export default translation(VacancyTabs);
