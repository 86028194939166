import { PrinterOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BasicTrigger, TriggerType } from 'lux/components/ControlsTrigger';
import translation from 'lux/components/translation';

const TrlKeys = {
    print: 'vacancy.print',
};

interface PrintActionProps {
    Component: React.FC<BasicTrigger>;
    triggerType: TriggerType;
    triggerName: string;
    vacancyId: number;
    buttonsContainerRef: React.RefObject<HTMLInputElement>;
}

const PrintAction: TranslatedComponent<PrintActionProps> = ({ Component, triggerType, trls, vacancyId }) => {
    return (
        <Component
            triggerName="print"
            triggerType={triggerType}
            triggerHref={`/vacancy/view?id=${vacancyId}&print=true`}
            isPermitted
            icon={<PrinterOutlinedSize24 />}
        >
            {(triggerType === 'menu' || triggerType === 'actionList') && trls[TrlKeys.print]}
        </Component>
    );
};

export default translation(PrintAction);
