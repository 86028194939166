import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { RelatedVacanciesType } from 'lux/components/RelatedVacancies/relatedVacanciesTypes';
import translation from 'lux/components/translation';

const TrlKeys = {
    suitable: 'vacancy.suitableVacancyForEmployer.title',
    similar: 'vacancy.similarVacancyForEmployer.title',
};

const Title: TranslatedComponent<{ type?: RelatedVacanciesType }> = ({ type, trls }) => {
    if (type === RelatedVacanciesType.Suitable) {
        return <>{trls[TrlKeys.suitable]}</>;
    }
    return <>{trls[TrlKeys.similar]}</>;
};

export default translation(Title);
