// tempexp_32165_file
import { FC, ReactNode } from 'react';

import ExperimentCheckOnElementShown from 'lux/components/ExperimentCheckOnElementShown';
import useExperiment from 'lux/hooks/useExperiment';
import { useIsHeadHunterPlatform } from 'lux/hooks/usePlatform';
import { useIsHhru } from 'lux/hooks/useSites';
import { CountryId } from 'lux/models/countryId';
import { useSelector } from 'lux/modules/useSelector';

export const EXPERIMENT_NAME_RISE_STANDARD_SALE_30 = 'exp_32165_rise_standard_sale_30';
export const EXPERIMENT_NAME_RISE_STANDARD_SALE_70 = 'exp_32165_rise_standard_sale_70';
interface ExperimentCheckOnElementShownRiseStandardSaleProps {
    vacancy?: boolean;
    children: ReactNode;
}
const ExperimentCheckOnElementShownRiseStandardSale: FC<ExperimentCheckOnElementShownRiseStandardSaleProps> = ({
    children,
    vacancy,
}) => {
    const { sendCheckVacancies, sendCheckVacancy } = useRiseStandardSale();
    const sendCheck = vacancy ? sendCheckVacancy : sendCheckVacancies;

    return sendCheck ? (
        <ExperimentCheckOnElementShown experimentName={EXPERIMENT_NAME_RISE_STANDARD_SALE_30}>
            <ExperimentCheckOnElementShown experimentName={EXPERIMENT_NAME_RISE_STANDARD_SALE_70}>
                {children}
            </ExperimentCheckOnElementShown>
        </ExperimentCheckOnElementShown>
    ) : (
        <>{children}</>
    );
};
interface UseRiseStandardSale {
    isExpRiseStandardSaleVacancies: boolean;
    isExpRiseStandardSaleVacancy: boolean;
    sendCheckVacancies: boolean;
    sendCheckVacancy: boolean;
}
export function useRiseStandardSale(): UseRiseStandardSale {
    const isRussianEmployer = useSelector(
        ({ employerCountryIdBySeller }) => employerCountryIdBySeller?.toString() === CountryId.Russia
    );
    const isHHPlatform = useIsHeadHunterPlatform();
    const isHHRu = useIsHhru();
    const shouldCheckExperiment = isHHPlatform && isHHRu && isRussianEmployer;

    const isExpRiseStandardSale30 = useExperiment('exp_32165_rise_standard_sale_30', shouldCheckExperiment, false);
    const isExpRiseStandardSale70 = useExperiment('exp_32165_rise_standard_sale_70', shouldCheckExperiment, false);
    const isExpRiseStandardSale = isExpRiseStandardSale30 || isExpRiseStandardSale70;
    // отправляем ли AB на странице «Мои вакансии»
    const sendCheckVacancies = useSelector(({ managerVacancies }) => !!managerVacancies.riseStandardSaleSendCheck);
    // будет true, если ab в тесте и все условия экспа соблюдены(РФ,рубли,HH,есть опубликованная вакансия Стандарт)
    const isExpRiseStandardSaleVacancies = isExpRiseStandardSale && sendCheckVacancies;

    // отправляем ли AB на странице «Вакансия»
    const sendCheckVacancy = useSelector(({ riseStandardSaleVacancy }) => riseStandardSaleVacancy.sendCheck);
    // будет true, если ab в тесте и все условия экспа соблюдены(РФ,рубли,HH,открытая вакансия типа Стандарт)
    const isExpRiseStandardSaleVacancy = isExpRiseStandardSale && sendCheckVacancy;

    return {
        sendCheckVacancies: shouldCheckExperiment && sendCheckVacancies,
        sendCheckVacancy: shouldCheckExperiment && sendCheckVacancy,
        isExpRiseStandardSaleVacancies,
        isExpRiseStandardSaleVacancy,
    };
}

export default ExperimentCheckOnElementShownRiseStandardSale;
