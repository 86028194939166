/*
 * Т.к. в магрите всё ещё нет компонента SegmentedControl, то мы позаимствуем его
 * из https://github.com/hhru/career-platform-front/pull/222/files
 */

import { FunctionComponent, MouseEvent, ReactNode } from 'react';
import classnames from 'classnames';

import styles from './index.less';

interface SegmentedControlProps<T extends string> {
    active: T;
    sections: Record<T, string | ReactNode>;
    onSelect: (value: T) => void;
    size?: 'large' | 'medium';
}

const SegmentedControl = <T extends string>({
    active,
    sections,
    onSelect,
    size = 'large',
}: SegmentedControlProps<T>): ReturnType<FunctionComponent<SegmentedControlProps<T>>> => {
    const onClick = (key: T) => (e: MouseEvent) => {
        e.preventDefault();
        if (key !== active) {
            onSelect(key);
        }
    };

    return (
        <nav className={classnames(styles.nav, { [styles.navSizeMedium]: size === 'medium' })}>
            {Object.keys(sections).map((key) => (
                <div
                    key={key}
                    className={classnames(styles.item, { [styles.itemSelected]: key === active })}
                    onClick={onClick(key as T)}
                    data-qa={`segmented-item-${key}`}
                >
                    {sections[key as T]}
                </div>
            ))}
        </nav>
    );
};

export default SegmentedControl;
