import { useRef, useEffect, FC, useContext } from 'react';
import { select } from 'd3-selection';

import ChartContext from 'lux/components/VacancyView/SalaryMarketRangeChart/ChartContainer/ChartContext';
import { CHART_HEIGHT, RANGE_TO_PERCENT } from 'lux/components/VacancyView/SalaryMarketRangeChart/constants';
import { ChartProps, SalaryGradient } from 'lux/components/VacancyView/SalaryMarketRangeChart/types';
import { useChartData, useChartColor } from 'lux/components/VacancyView/SalaryMarketRangeChart/utils';
import withChartContainer from 'lux/components/VacancyView/SalaryMarketRangeChart/withChartContainer';

import styles from './salary-range-chart.less';

const CHART_RADIUS = 12;
const CHART_BACKGROUND_RADIUS = 30;

const RECT_HEIGHT = 24;
const OFFSET_Y_RECT = 8;

const getLinearGradient = (color: string, gradient: SalaryGradient | null) => {
    const xCoords = gradient === SalaryGradient.In ? { x1: '100%', x2: 0 } : { x: '0%', x2: '100%' };
    return (
        <linearGradient id={`linear-gradient-${color}`} {...xCoords} y1="0%" y2="0%">
            <stop offset="0%" stopColor={color} stopOpacity="0%" />
            <stop offset="40%" stopColor={color} stopOpacity="10%" />
            <stop offset="100%" stopColor={color} stopOpacity="100%" />
        </linearGradient>
    );
};

const SalaryRangeChart: FC<ChartProps> = ({ data }) => {
    const { xAxis } = useContext(ChartContext);
    const rectChartRef = useRef<SVGGElement | null>(null);

    const { from, to, gradient, isOutOfRange } = useChartData(data);
    const hasGradient = Object.values(SalaryGradient).includes(gradient);

    const color = useChartColor();

    useEffect(() => {
        if (!rectChartRef.current) {
            return undefined;
        }

        const gRect = select(rectChartRef.current);
        const rect = gRect.selectAll('rect').data(RANGE_TO_PERCENT).enter();

        if ((from !== 0 && from === to) || isOutOfRange) {
            gRect
                .append('circle')
                .attr('fill', color)
                .attr('stroke', 'none')
                .attr('cx', xAxis(from))
                .attr('cy', CHART_HEIGHT / 2)
                .attr('r', isOutOfRange ? 6 : CHART_RADIUS);

            gRect
                .append('circle')
                .attr('fill', color)
                .attr('stroke', 'none')
                .attr('cx', xAxis(from))
                .attr('cy', CHART_HEIGHT / 2)
                .attr('r', isOutOfRange ? 6 : CHART_RADIUS)
                .attr('class', styles.filter);
        }

        if (!isOutOfRange && from !== to) {
            // salary-shadow
            gRect
                .append('rect')
                .attr('x', xAxis(from))
                .attr('y', 0)
                .attr('width', xAxis(to) - xAxis(from))
                .attr('height', CHART_HEIGHT)
                .attr('fill', color)
                .attr('class', styles.filter);

            // salary
            gRect
                .append('rect')
                .attr('x', xAxis(from))
                .attr('y', OFFSET_Y_RECT)
                .attr('width', xAxis(to) - xAxis(from))
                .attr('height', RECT_HEIGHT)
                .attr('rx', CHART_RADIUS)
                .attr('fill', hasGradient ? `url(#linear-gradient-${color})` : color);
        }

        // ranges
        rect.append('rect')
            .attr('x', (d) => xAxis(d))
            .attr('y', 10)
            .attr('width', 2)
            .attr('height', 20)
            .attr('rx', 2)
            .attr('class', styles.rangeColor);

        return () => {
            ['rect', 'circle'].forEach((el) => gRect.selectAll(el).remove());
        };
    }, [xAxis, from, to, hasGradient, isOutOfRange, color]);

    return (
        <>
            <g>
                <rect
                    x="0"
                    y="0"
                    width={xAxis(100)}
                    height={CHART_HEIGHT}
                    rx={CHART_BACKGROUND_RADIUS}
                    className={styles.chartBackground}
                />
            </g>
            <g ref={rectChartRef}>{hasGradient && getLinearGradient(color, gradient)}</g>
        </>
    );
};

export default withChartContainer(SalaryRangeChart);
