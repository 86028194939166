import { Text, Loader } from '@hh.ru/magritte-ui';
import { ExclamationTriangleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { LoadingState } from 'lux/models/vacancyConversionStatistics';

import styles from './loading-result.less';

const TrlKeys = {
    message: 'statportal.report.dashboard.empty',
};

interface LoadingResultProps {
    loadingState: LoadingState;
}

const LoadingResult: TranslatedComponent<LoadingResultProps> = ({ loadingState, trls }) => {
    if (loadingState === LoadingState.SUCCESS) {
        return null;
    }

    return (
        <div className={styles.loadingWrapper}>
            {loadingState === LoadingState.NONE && <Loader size={24} />}
            {loadingState === LoadingState.ERROR && (
                <span className={styles.loadingError}>
                    <ExclamationTriangleOutlinedSize24 initial="secondary" />
                    <Text style="secondary">{trls[TrlKeys.message]}</Text>
                </span>
            )}
        </div>
    );
};

export default translation(LoadingResult);
