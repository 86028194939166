import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CalendarOutlinedSize24, CrossCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { useNotification } from 'lux/components/Notifications/Provider';
import { ADD_MODAL, MODAL_AUTO_PROLONG } from 'lux/components/VacancyModals/constants';
import translation from 'lux/components/translation';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

import getMenuButtonIconProps from 'lux/components/VacancyActions/getMenuButtonIconProps';
import { UPDATE_CREATE_PERMISSION } from 'lux/components/VacancyActions/permissions';
import vacancyAutoProlongation from 'lux/components/VacancyActions/vacancyAutoProlongation';

const openModal = ({ dispatchModal, onClose, ...data }) => {
    dispatchModal({
        type: ADD_MODAL,
        payload: {
            modalType: MODAL_AUTO_PROLONG,
            data,
        },
    });
};

const disableAutoProlongation = async (
    { vacanciesIds, setLoading, onClose, vacancyAutoProlongation, location },
    addNotification
) => {
    setLoading(true);
    try {
        await vacancyAutoProlongation({ action: 'disable', vacanciesIds, location }, addNotification);
    } finally {
        onClose?.();
        setLoading(false);
    }
};

const openModalWithRequirements = async ({ vacanciesIds, setLoading, addNotification, onClose, modalParams }) => {
    setLoading(true);
    let requirements;
    try {
        requirements = (
            await fetcher.put('/shards/employer/vacancies/auto_prolongation/requirements', { vacanciesIds })
        )?.data;
    } catch (error) {
        defaultRequestErrorHandler(error, addNotification);
        return;
    } finally {
        onClose?.();
        setLoading(false);
    }

    openModal({ ...modalParams, requirements });
};

const useTriggerAction = ({
    isAutoProlongationPlanned,
    vacanciesIds,
    managerIds,
    vacancyAutoProlongation,
    onClose,
    dispatchModal,
    isLoading,
    setLoading,
    location,
    afterSuccessAction,
}) => {
    const { addNotification } = useNotification();
    return useCallback(async () => {
        if (isLoading) {
            return;
        }

        if (isAutoProlongationPlanned) {
            await disableAutoProlongation(
                {
                    vacanciesIds,
                    setLoading,
                    onClose,
                    vacancyAutoProlongation,
                    location,
                },
                addNotification
            );
            return;
        }

        const modalParams = { dispatchModal, vacanciesIds, managerIds, afterSuccessAction };

        await openModalWithRequirements({ vacanciesIds, setLoading, addNotification, onClose, modalParams });
    }, [
        isLoading,
        isAutoProlongationPlanned,
        dispatchModal,
        vacanciesIds,
        managerIds,
        afterSuccessAction,
        setLoading,
        onClose,
        vacancyAutoProlongation,
        location,
        addNotification,
    ]);
};

const AutoProlongationAction = ({
    Component,
    triggerType,
    permissions,
    additionalAnalyticsParams,
    vacanciesIds,
    trls,
    icon,
    ...triggerActionParams
}) => {
    const [isLoading, setLoading] = useState(false);

    const handleTrigger = useTriggerAction({ ...triggerActionParams, isLoading, setLoading, vacanciesIds });
    const action = triggerActionParams.isAutoProlongationPlanned ? 'disable' : 'enable';
    const triggerName = 'auto-prolongation-toggle';
    const iconProps = getMenuButtonIconProps({
        triggerType,
        triggerName,
        icon: action === 'enable' ? <CalendarOutlinedSize24 /> : <CrossCircleOutlinedSize24 />,
    });

    return (
        <Component
            triggerName={triggerName}
            triggerType={triggerType}
            onTrigger={handleTrigger}
            isPermitted={permissions.includes(UPDATE_CREATE_PERMISSION)}
            isLoading={isLoading}
            additionalAnalyticsParams={{ ...additionalAnalyticsParams, action }}
            batchLength={vacanciesIds.length}
            icon={icon}
            {...iconProps}
        >
            {trls[AutoProlongationAction.trls[action]]}
        </Component>
    );
};

AutoProlongationAction.trls = {
    enable: 'employer.vacancies.autoProlongation.enable',
    disable: 'employer.vacancies.autoProlongation.disable',
};

AutoProlongationAction.propTypes = {
    Component: PropTypes.elementType.isRequired,
    triggerType: PropTypes.string,
    onClose: PropTypes.func,
    vacanciesIds: PropTypes.array,
    dispatchModal: PropTypes.func,
    permissions: PropTypes.array,
    managerIds: PropTypes.array,
    isAutoProlongationPlanned: PropTypes.bool,
    vacancyAutoProlongation: PropTypes.func,
    additionalAnalyticsParams: PropTypes.object,
    trls: PropTypes.object,
    location: PropTypes.object,
    afterSuccessAction: PropTypes.func,
    icon: PropTypes.element,
};

export default connect(({ permissions, router: { location } }) => ({ permissions, location }), {
    vacancyAutoProlongation,
})(translation(AutoProlongationAction));
