import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';

import { MIDDLE_DOT } from 'lux/modules/symbols';

import styles from './vacancy-key-info.less';

interface KeyInfoItemProps {
    children: ReactNode;
    dataQa?: string;
    first?: boolean;
    setMaxLength?: boolean;
}

const VacancyKeyInfoItem: FC<KeyInfoItemProps> = ({ children, dataQa, first = false, setMaxLength = false }) => (
    <>
        {!first && <span className={styles.vacancyKeyInfoDot}>{MIDDLE_DOT}</span>}
        <span
            className={classnames(styles.vacancyKeyInfoItem, {
                [styles.vacancyKeyInfoItemMaxLength]: setMaxLength,
            })}
            data-qa={dataQa}
        >
            <Text Element="span" typography="label-2-regular">
                {children}
            </Text>
        </span>
    </>
);

export default VacancyKeyInfoItem;
