import { toDate } from 'bloko/blocks/calendar/datesHelper';

import formatDate from 'Modules/formatDate';

const HUMAN_DATE_FORMAT = 'd MMM yyyy';
const HUMAN_DATE_FORMAT_FULL_MONTH = 'd MMMM yyyy';

type FormattedDateType = string | null;

/**
 * Возвращает дату в формате 1 янв. 2021
 * Второй параметр позволяет вернуть полное наименование месяца - 1 января 2021
 */
export const formatHumanDate = (date: string | Date, fullMonthName = false): FormattedDateType => {
    if (!date) {
        return null;
    }
    return formatDate(
        typeof date === 'string' ? toDate(date) : date,
        fullMonthName ? HUMAN_DATE_FORMAT_FULL_MONTH : HUMAN_DATE_FORMAT
    );
};

/**
 * Возвращает интервал дат, объединяя одинаковые даты, месяцы и года, например
 * 31.12.2020 - 31.12.2020 -> 31 дек. 2020
 * 11.02.2020 - 15.02.2020 -> 11 – 15 фев. 2020
 * 01.01.2020 - 01.02.2020 -> 1 янв. – 1 фев. 2020
 * 01.01.2020 - 01.01.2021 -> 1 янв. 2020 – 1 янв. 2021
 */
export const formatMergedDates = (start: string, end: string): FormattedDateType => {
    const formattedStartDate = formatHumanDate(start);
    const formattedEndDate = formatHumanDate(end);

    if (!formattedStartDate || !formattedEndDate) {
        return null;
    }

    const [startDay, startMonth, startYear] = formattedStartDate.split(' ');
    const [endDay, endMonth, endYear] = formattedEndDate.split(' ');

    if (start === end) {
        return formattedEndDate;
    }
    if (startMonth === endMonth && startYear === endYear) {
        return `${startDay} – ${endDay} ${endMonth} ${endYear}`;
    }
    if (startYear === endYear) {
        return `${startDay} ${startMonth} – ${endDay} ${endMonth} ${endYear}`;
    }
    return `${formattedStartDate} – ${formattedEndDate}`;
};
