import { FC, ReactNode, MouseEvent } from 'react';

import { Action, Text, VSpacing, Title } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import styles from './tooltip-onboarding-content.less';

interface TooltipOnboardingContentProps {
    title: string;
    text: string;
    actions?: ReactNode;
    onClose: (event: MouseEvent) => void;
    onShown: (element: HTMLDivElement) => void;
}

const TooltipOnboardingContent: FC<TooltipOnboardingContentProps> = ({ title, text, actions, onClose, onShown }) => {
    return (
        <div
            ref={(element) => {
                if (element) {
                    onShown(element);
                }
            }}
        >
            <div className={styles.tooltipTitle}>
                <Title Element="h3" size="small">
                    {title}
                </Title>
                <Action icon={CrossOutlinedSize24} onClick={onClose} mode="secondary" style="contrast" />
            </div>
            <VSpacing default={8} />
            <Text>{text}</Text>
            {actions && <div className={styles.tooltipActions}>{actions}</div>}
        </div>
    );
};

export default TooltipOnboardingContent;
