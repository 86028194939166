import React, { useRef, useState, FC, useCallback, useEffect, PropsWithChildren } from 'react';

import { useResize } from '@hh.ru/magritte-ui';
import throttle from 'bloko/common/throttle';

import Line from 'lux/pages/VacancyView/components/VacancyStatTab/Chart/Line';

import ChartContainer, {
    ChartContainerProps,
} from 'lux/pages/VacancyView/components/VacancyStatTab/Chart/LineChart/ChartContainer';

const CHART_DEFAULT_WIDTH = 100;
const CHART_THROTTLE_DELAY_MS = 400;

type LineChartProps = Omit<ChartContainerProps, 'width'>;

const LineChart: FC<LineChartProps & PropsWithChildren> = ({ children, ...chartProps }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(CHART_DEFAULT_WIDTH);

    const recalcChart = useCallback(() => {
        setWidth(containerRef.current?.offsetWidth || CHART_DEFAULT_WIDTH);
    }, [containerRef]);

    useEffect(() => {
        recalcChart();
    }, [containerRef, recalcChart]);

    useResize(throttle(recalcChart, CHART_THROTTLE_DELAY_MS));

    return (
        <div ref={containerRef}>
            <ChartContainer {...chartProps} width={width}>
                {children}
            </ChartContainer>
        </div>
    );
};

export { Line };

export default LineChart;
