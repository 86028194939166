import classnames from 'classnames';

import { Link, Text, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import HumanDate from 'lux/components/HumanDate';
import translation from 'lux/components/translation';
import useToggleState from 'lux/hooks/useToggleState';

import styles from './timeline.less';

const TrlKeys = {
    noData: 'timeline.noData',
    expand: 'toggle.expand.all',
    collapse: 'timeline.collapse',
};

const DEFAULT_SHOW_ITEMS = 3;

export interface TimelineItem {
    dateTime: string | number;
    title: string;
    text?: string;
}

export type TimelineData = Array<TimelineItem & { id: number | string }>;

interface TimelineProps {
    data: TimelineData;
    title: string;
    showTime?: boolean;
    onShowMoreCallback?: () => void;
}

const Timeline: TranslatedComponent<TimelineProps> = ({ data, title, onShowMoreCallback, showTime = false, trls }) => {
    const [visible, toggle] = useToggleState(false);
    const { isXS } = useBreakpoint();

    if (!data.length) {
        return (
            <>
                <Text typography="label-2-regular">{title}</Text>
                <VSpacing default={16} />
                <div className={styles.timeline}>
                    <Text Element="span" style="secondary">
                        <p className={styles.timelineNoData}>{trls[TrlKeys.noData]}</p>
                    </Text>
                </div>
            </>
        );
    }

    const showExpandButton = data.length > DEFAULT_SHOW_ITEMS;
    const items = visible ? data : data.slice(0, DEFAULT_SHOW_ITEMS);

    const onToggle = () => {
        if (!visible) {
            onShowMoreCallback?.();
        }
        toggle();
    };

    return (
        <>
            <Text typography="label-2-regular">{title}</Text>
            <VSpacing default={16} />
            <ul className={styles.timeline}>
                {items.map((item) => (
                    <li
                        className={classnames(styles.timelineItem, {
                            [styles.timelineProgress]: !isXS,
                            [styles.timelineItemColumn]: isXS,
                        })}
                        key={item.id}
                    >
                        <div className={isXS ? styles.timelineItemDateTimeRow : styles.timelineItemDateTime}>
                            <Text Element="span" typography="label-2-regular">
                                <HumanDate date={item.dateTime} />
                            </Text>
                            {showTime && (
                                <Text Element="span" style="secondary" typography="label-2-regular">
                                    {formatDate(item.dateTime, 'HH:mm')}
                                </Text>
                            )}
                        </div>
                        <div
                            className={classnames(styles.timelineItemText, {
                                [styles.timelineCircle]: !isXS,
                                [styles.timelineItemTextPadding]: !isXS,
                            })}
                        >
                            <Text typography="label-2-regular">{item.title}</Text>
                            {Boolean(item.text) && (
                                <Text style="secondary" typography="label-2-regular">
                                    {item.text}
                                </Text>
                            )}
                        </div>
                    </li>
                ))}
                {showExpandButton && (
                    <li className={styles.timelineExpandButton}>
                        <Link Element="button" onClick={onToggle} data-qa="add-comment">
                            {trls[visible ? TrlKeys.collapse : TrlKeys.expand]}
                        </Link>
                    </li>
                )}
            </ul>
        </>
    );
};

export default translation(Timeline);
