import { createContext } from 'react';
import { scaleLinear } from 'd3-scale';

import { XAxis } from 'lux/components/VacancyView/SalaryMarketRangeChart/types';

interface ChartContextType {
    xAxis: XAxis;
}

const ChartContext = createContext<ChartContextType>({ xAxis: scaleLinear([0]) });

export default ChartContext;
