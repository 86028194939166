import PropTypes from 'prop-types';

import { VSpacing, VSpacingContainer, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

import AuctionBanner from 'lux/components/Auction/Banner';
import useAuctionCreationEnabled from 'lux/components/Auction/hooks/useAuctionCreationEnabled';
import useAuctionEditingEnabled from 'lux/components/Auction/hooks/useAuctionEditingEnabled';
import SuitableVacanciesBanner from 'lux/components/SuitableVacanciesBanner';
import VacancyImportantBanners from 'lux/components/VacancyImportantBanners';
import VacancyViewPropTypes from 'lux/components/VacancyView/propTypes';
import translation from 'lux/components/translation';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import AuctionStatistic from 'lux/pages/VacancyView/components/AuctionStatistic';
import DisableCallTracking from 'lux/pages/VacancyView/components/DisableCallTracking';

const VacancyEmployerSidebar = ({ vacancyView, dispatchModal, trls }) => {
    const userType = useSelector((state) => state.userType);
    const isBackofficeUser = userType === UserType.BackOffice;
    const canEditAuction = useAuctionEditingEnabled();
    const auctionCreationEnabled = useAuctionCreationEnabled();
    const { isGtS } = useBreakpoint();

    if (!vacancyView.systemInfo) {
        return null;
    }

    return (
        <div className="vacancy-history">
            <VSpacingContainer default={26}>
                {isGtS && (
                    <VacancyImportantBanners
                        vacancyId={vacancyView.vacancyId}
                        vacancyName={vacancyView.name}
                        dispatchModal={dispatchModal}
                    />
                )}
                <SuitableVacanciesBanner vacancyId={vacancyView.vacancyId} />
                {auctionCreationEnabled && isGtS && <AuctionBanner />}
                {canEditAuction && <AuctionStatistic />}
            </VSpacingContainer>
            {isBackofficeUser && (
                <div>
                    <BlokoLink
                        Element={Link}
                        disableVisited
                        to={`/admin/vacancy/snapshot.do?vacancyId=${vacancyView.vacancyId}`}
                        kind={LinkKind.Tertiary}
                    >
                        {trls[VacancyEmployerSidebar.trls.lastsnapshot]}
                    </BlokoLink>
                </div>
            )}
            <VSpacing default={14} />
            <DisableCallTracking vacancyId={vacancyView.vacancyId} />
            <VSpacing default={14} />
        </div>
    );
};

VacancyEmployerSidebar.propTypes = {
    userType: PropTypes.string,
    vacancyView: VacancyViewPropTypes.vacancyView,
    dispatchModal: PropTypes.func,
    trls: PropTypes.object,
};

VacancyEmployerSidebar.trls = {
    published: 'employer.vacancy.changesHistory.publish',
    created: 'employer.vacancy.changesHistory.create',
    site: 'employer.vacancy.changesHistory.jobSite.HH',
    expires: 'employer.vacancy.changesHistory.expires',
    autoprolongation: 'employer.vacancies.autoProlongation.plannedBy',
    code: 'vacancy.header.vacancyCode',
    stat: 'vacancy.stat.link',
    lastsnapshot: 'vacancy.lastVacancySnapshot.link',
};

export default translation(VacancyEmployerSidebar);
