import { RangeToPercent, ChartColor } from 'lux/components/VacancyView/SalaryMarketRangeChart/types';

/**
 * Значения ЗП раскладываем по процентам
 */
export const RANGE_TO_PERCENT: RangeToPercent = [5, 35, 65, 95];

export const COLOR_OFF_MARKET = ChartColor.Red70;
export const COLOR_MARKET = ChartColor.Orange70;
export const COLOR_ABOVE_MARKET = ChartColor.Violet75;

export const CHART_HEIGHT = 40;
export const MIN_CHART_PERCENT = 0;
export const MAX_CHART_PERCENT = 100;
