import { useState, useRef, useCallback, useMemo, useEffect, FC, PropsWithChildren } from 'react';

import { useResize } from '@hh.ru/magritte-ui';
import throttle from 'bloko/common/throttle';

import { MIN_CHART_PERCENT, MAX_CHART_PERCENT } from 'lux/components/VacancyView/SalaryMarketRangeChart/constants';
import { getXAxis } from 'lux/components/VacancyView/SalaryMarketRangeChart/utils';

import ChartContext from 'lux/components/VacancyView/SalaryMarketRangeChart/ChartContainer/ChartContext';

import styles from './chart-container.less';

const CHART_WIDTH = 200;
const CHART_THROTTLE_DELAY_MS = 400;

export interface ChartContainerProps {
    height: number;
}

const ChartContainer: FC<ChartContainerProps & PropsWithChildren> = ({ children, height }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(CHART_WIDTH);

    const xAxis = useMemo(() => getXAxis(MIN_CHART_PERCENT, MAX_CHART_PERCENT, width), [width]);

    const recalcChart = useCallback(() => {
        const containerWidth = containerRef.current?.getBoundingClientRect()?.width;
        setWidth(containerWidth || CHART_WIDTH);
    }, []);

    useEffect(() => {
        if (containerRef.current) {
            recalcChart();
        }
    }, [recalcChart]);

    useResize(throttle(recalcChart, CHART_THROTTLE_DELAY_MS));

    return (
        <div ref={containerRef} className={styles.chartContainer}>
            <ChartContext.Provider value={{ xAxis }}>
                <svg style={{ overflow: 'visible' }} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                    {children}
                </svg>
            </ChartContext.Provider>
        </div>
    );
};

export default ChartContainer;
