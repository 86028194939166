import { useState } from 'react';

import Info, { InfoPlacement } from 'bloko/blocks/drop/Info';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notices from 'Modules/Notices';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    content: 'employer.vacancy.videointerview.tip.content',
};

const NAME = 'videointerview_vacancy_menu';

interface VideoInterviewInfoProps {
    children: JSX.Element;
    showInfo?: boolean;
}

const VideoInterviewInfo: TranslatedComponent<VideoInterviewInfoProps> = ({ children, showInfo, trls }) => {
    const [show, setShow] = useState(true);
    if (useSelector((state) => state.infoTip?.name !== NAME) || !showInfo) {
        return children;
    }
    return (
        <Info
            onClose={() => {
                setShow(false);
                Notices.markAsViewed(NAME);
            }}
            placement={InfoPlacement.TopStart}
            render={() => <div className="vacancy-videointerview-tip">{trls[TrlKeys.content]}</div>}
            show={show}
        >
            {children}
        </Info>
    );
};
export default translation(VideoInterviewInfo);
