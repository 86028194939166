import PropTypes from 'prop-types';

import VacancyViewPropTypes from 'lux/components/VacancyView/propTypes';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import VacancyEmployerActions from 'lux/pages/VacancyView/components/VacancyEmployerActions';

const VacancyHeader = ({ checkedVacancies, dispatchModal, vacancyView }) => {
    const { availableVacancyActions, permissions, userType } = useSelector((state) => ({
        availableVacancyActions: state.availableVacancyActions,
        permissions: state.permissions,
        userType: state.userType,
    }));

    const hasAvailableActions =
        availableVacancyActions.update ||
        availableVacancyActions.edit ||
        availableVacancyActions.duplicate ||
        availableVacancyActions.archive ||
        availableVacancyActions.upgrade ||
        availableVacancyActions.delete ||
        availableVacancyActions.restore ||
        availableVacancyActions.hide ||
        availableVacancyActions.restoreToArchive ||
        permissions.includes('ModerateVacancy') ||
        permissions.includes('VacModerList');

    if (!hasAvailableActions && !vacancyView.canViewResponses) {
        return null;
    }

    return (
        <div className="vacancy-header">
            {hasAvailableActions && (
                <VacancyEmployerActions
                    checkedVacancies={checkedVacancies}
                    dispatchModal={dispatchModal}
                    vacancyView={vacancyView}
                    isBackoffice={userType === UserType.BackOffice}
                />
            )}
        </div>
    );
};

VacancyHeader.propTypes = {
    checkedVacancies: PropTypes.object,
    dispatchModal: PropTypes.func,
    vacancyView: VacancyViewPropTypes.vacancyView,
};

export default VacancyHeader;
