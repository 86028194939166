import { useRef, useEffect, FC, useContext } from 'react';
import { select } from 'd3-selection';

import ChartContext from 'lux/components/VacancyView/SalaryMarketRangeChart/ChartContainer/ChartContext';
import { RANGE_TO_PERCENT } from 'lux/components/VacancyView/SalaryMarketRangeChart/constants';
import { ChartProps } from 'lux/components/VacancyView/SalaryMarketRangeChart/types';
import withChartContainer from 'lux/components/VacancyView/SalaryMarketRangeChart/withChartContainer';
import formatToInt from 'lux/utils/formatToInt';

import styles from './ticks-x-axis.less';

const OFFSET_Y = 16; // equal font-size

const TicksXAxis: FC<ChartProps> = ({ data }) => {
    const { xAxis } = useContext(ChartContext);
    const textChartRef = useRef<SVGGElement | null>(null);

    useEffect(() => {
        if (!textChartRef.current) {
            return undefined;
        }

        const gText = select(textChartRef.current).selectAll('text').data(data).enter();

        gText
            .append('text')
            .attr('x', (d, ind) => xAxis(RANGE_TO_PERCENT[ind]))
            .attr('y', OFFSET_Y)
            .text((d) => formatToInt(d))
            .attr('class', styles.ticksXAxis);

        return () => {
            gText.selectAll('text').remove();
        };
    }, [xAxis, data]);

    return <g ref={textChartRef} />;
};

export default withChartContainer(TicksXAxis);
