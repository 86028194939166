import { FC } from 'react';

import Text, { TextSize } from 'bloko/blocks/text';

import styles from './vacancy-conversion-info.less';

interface ColumnTextValueProps {
    title: string;
    value: string | null;
}

const ColumnBox: FC<ColumnTextValueProps> = ({ title, value }) => (
    <div className={styles.columnBox}>
        <span className={styles.valueColor}>
            <Text Element="span" size={TextSize.Medium}>
                {value}
            </Text>
        </span>
        <Text Element="span">{title}</Text>
    </div>
);

export default ColumnBox;
