import React, { useContext, useEffect, useRef, FC } from 'react';
import { select } from 'd3-selection';
import { line } from 'd3-shape';

import formatDate from 'Modules/formatDate';
import ChartContext from 'lux/pages/VacancyView/components/VacancyStatTab/Chart/LineChart/ChartContext';
import Color from 'lux/pages/VacancyView/components/VacancyStatTab/Chart/color';

import styles from './axis.less';

// Отступ текста от линии оси
const TEXT_OFFSET = 20;
const DEFAULT_LABEL_FORMAT = 'dd.MM';
const NUM_OF_DAYOFF = [0, 6];

interface AxisProps {
    dateLabelFormat?: string;
}

const Axis: FC<AxisProps> = ({ dateLabelFormat = DEFAULT_LABEL_FORMAT }) => {
    const { xAxis, data } = useContext(ChartContext).chartData;
    const { width, height, chartDimension } = useContext(ChartContext);

    const pathRef = useRef<SVGPathElement | null>(null);
    const textRef = useRef<SVGGElement | null>(null);

    const [xMin, xMax] = xAxis.range();

    useEffect(() => {
        if (!pathRef.current) {
            return;
        }

        const path = line()
            .x((d) => d[0])
            .y(0);

        const lineArea = path([
            [xMin, 0],
            [xMax, 0],
        ]);

        const pathArea = select(pathRef.current).attr('d', lineArea);
        const filtered = data.filter((p, ind) => !(ind % chartDimension) && !p.additional?.isFake);

        const text = select(textRef.current).selectAll('text').data(filtered).enter();

        text.append('text')
            .attr('x', (d) => xAxis(d.date))
            .attr('y', TEXT_OFFSET)
            .text((d) => formatDate(d.rawDate, dateLabelFormat))
            .classed(styles.axisTick, true)
            .classed(styles.dayOff, (d) => NUM_OF_DAYOFF.includes(d.date.getDay()));

        // eslint-disable-next-line consistent-return
        return () => {
            pathArea.selectAll('path').remove();
            text.selectAll('text').remove();
        };
    }, [xAxis, width, data, xMax, height, xMin, chartDimension, dateLabelFormat]);

    return (
        <>
            <path stroke={Color.Gray20} ref={pathRef} />
            <g ref={textRef} />
        </>
    );
};

export default Axis;
