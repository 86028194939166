import { FC, ReactElement } from 'react';

import { Card, VSpacing } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import styles from './banner-wrapper.less';

interface BannerWrapperProps {
    children: ReactElement;
    header?: string;
    dataQa?: string;
}

const BannerWrapper: FC<BannerWrapperProps> = ({ header, dataQa, children }) => {
    return (
        <Card data-qa={dataQa} showBorder borderRadius={12} padding={24} stretched actionCard>
            <div className={styles.banner}>
                <div className={styles.bannerContent}>
                    {header && (
                        <>
                            <div className={styles.bannerHeader}>{header}</div>
                            <VSpacing default={16} />
                        </>
                    )}
                    {children}
                </div>
                <div className={styles.bannerIcon}>
                    <ChevronRightOutlinedSize16 />
                </div>
            </div>
        </Card>
    );
};

export default BannerWrapper;
