import { format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { useSelector } from 'lux/modules/useSelector';

interface UseVacancyViewMetaParams {
    trls: {
        pageTitle: string;
        jobIn: string;
        archived: string;
        archivedFrom: string;
        inCompany: string;
        titleCompensation: string;
        noCompensation: string;
        compensationFrom: string;
        compensationTo: string;
        workExperience: string;
        publicationDate: string;
        month: Record<string, string>;
        currencies: Record<string, string>;
        zp: {
            title: string;
            ogTitle: string;
            description: string;
            ogDescription: string;
        };
    };
}

interface UseVacancyMetaResult {
    title: string;
    ogTitle?: string;
    description: string;
    ogDescription: string;
    keywords: string;
}

const useVacancyViewMeta = ({ trls }: UseVacancyViewMetaParams): UseVacancyMetaResult => {
    const vacancyView = useSelector((state) => state.vacancyView);
    const vacancyTranslations = vacancyView.translations as Record<string, string>;
    const isZp = useIsZarplataPlatform();

    if (isZp) {
        const areaCatalogTitle = vacancyView.area?.areaCatalogTitle || '';
        const title = format(trls.zp.title, {
            '{vacancyName}': vacancyView.name,
            '{companyName}': vacancyView.company.visibleName,
            '{areaCatalogTitle}': areaCatalogTitle,
        });
        const ogTitle = format(trls.zp.ogTitle, {
            '{areaCatalogTitle}': areaCatalogTitle,
        });
        const description = format(trls.zp.description, {
            '{vacancyName}': vacancyView.name,
            '{areaCatalogTitle}': areaCatalogTitle,
        });
        const ogDescription = format(trls.zp.ogDescription, {
            '{areaCatalogTitle}': areaCatalogTitle,
        });
        return {
            title,
            ogTitle,
            description,
            ogDescription,
            keywords: title,
        };
    }

    // title part
    let archivedVacancyTitlePart = '';
    if (vacancyView?.status?.archived) {
        if (vacancyView.validThroughTime) {
            const dateObject = new Date(vacancyView.validThroughTime);

            archivedVacancyTitlePart = ` (${trls.archivedFrom} ${dateObject.getDate()} ${
                trls.month[dateObject.getMonth()]
            } ${dateObject.getFullYear()})`;
        } else {
            archivedVacancyTitlePart = ` ${trls.archived}`;
        }
    }

    const areaCatalogTitlePart = vacancyView?.area?.areaCatalogTitle ? ` ${vacancyView.area.areaCatalogTitle}` : '';
    const title = `${trls.pageTitle} ${vacancyView.name}${areaCatalogTitlePart}, ${format(trls.jobIn, {
        '{0}': vacancyView.company.visibleName,
    })}${archivedVacancyTitlePart}`;

    // description part
    let compensationPart = '';
    if (vacancyView.compensation.noCompensation) {
        compensationPart = trls.noCompensation;
    }
    if (vacancyView.compensation.from) {
        compensationPart = `${trls.compensationFrom} ${vacancyView.compensation.from} `;
    }
    if (vacancyView.compensation.to) {
        compensationPart += `${trls.compensationTo} ${vacancyView.compensation.to} `;
    }
    if (vacancyView.compensation.currencyCode) {
        compensationPart += trls.currencies[vacancyView.compensation.currencyCode].replace('.', '');
    }
    const areaPart = vacancyView.area?.name ? ` ${vacancyView.area.name}.` : '';
    const workExperiencePart = ` ${trls.workExperience}: ${vacancyTranslations.workExperience}.`;
    const employmentPart = ` ${vacancyTranslations.employment}.`;
    const publicationDatePart = vacancyView?.publicationDate
        ? ` ${trls.publicationDate}: ${formatDate(vacancyView.publicationDate, 'dd.MM.yyyy')}.`
        : '';

    const description =
        `${trls.pageTitle} ${vacancyView.name} ${trls.inCompany} ${vacancyView.company.visibleName}. ` +
        `${trls.titleCompensation}: ${compensationPart}.${areaPart}${workExperiencePart}${employmentPart}` +
        `${publicationDatePart}`;

    const ogDescription =
        `${trls.titleCompensation}: ${compensationPart}.${areaPart}${workExperiencePart}${employmentPart}` +
        `${publicationDatePart}`;

    return { title, description, ogDescription, keywords: title };
};

export default useVacancyViewMeta;
