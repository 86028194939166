import { ScaleLinear } from 'd3-scale';

export type MarketRange = [number, number, number, number];

export interface ChartProps {
    data: MarketRange;
}

export type XAxis = ScaleLinear<number, number>;

export enum SalaryGradient {
    In = 'in',
    Out = 'out',
}

export interface CompensationResult {
    gradient: SalaryGradient | null;
    from: number;
    to: number;
    /**
     * Признак уровень дохода ниже\выше рынка
     */
    isOutOfRange?: boolean;
}

export type RangeToPercent = MarketRange;

export enum ChartColor {
    // @magritte-color-base-red-70
    Red70 = '#FF8A77',
    // @magritte-color-base-orange-70
    Orange70 = '#FF9900',
    // magritte-color-base-violet-75
    Violet75 = '#a579f4',
}
