import { ComponentType, ReactNode, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import Text from 'bloko/blocks/text';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'lux/components/Notifications/Provider';
import useShowRisePage, {
    RISE_PAGE_DIRECT_EXPERIMENT,
    RISE_PAGE_MODAL_EXPERIMENT,
} from 'lux/components/Rise/useShowRisePage';
import { ADD_MODAL, MODAL_PROLONGATE } from 'lux/components/VacancyModals/constants';
import translation from 'lux/components/translation';
import { useExperimentGroupCheckFunction } from 'lux/hooks/useExperimentCheckFunction';
import { setRiseFailValidate, resetRiseValues } from 'lux/models/employerVacanciesRise';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';

import FreeRenewalTip from 'lux/components/VacancyActions/FreeRenewalTip';
import vacancyActionErrorNotification from 'lux/components/VacancyActions/VacancyActionError';
import fetchProlongateInfo from 'lux/components/VacancyActions/fetchProlongateInfo';
import { UPDATE_CREATE_PERMISSION } from 'lux/components/VacancyActions/permissions';
import { openBlankRisePage, validateResponse } from 'lux/components/VacancyActions/risePageExperimentUtils';

interface ComponentProps {
    actionType?: string;
    additionalAnalyticsParams?: Record<string, string>;
    batchLength: number;
    children: ReactNode;
    displayInOwnColumn?: boolean;
    icon?: ReactNode;
    isLoading: boolean;
    isPermitted: boolean;
    onTrigger: () => void;
    triggerName: string;
    triggerType: string;
}

export interface ProlongateActionProps {
    actionType?: string;
    additionalAnalyticsParams?: Record<string, string>;
    afterSuccessAction: () => void;
    Component: ComponentType<ComponentProps>;
    dispatchModal: ({ type, payload }: { type: string; payload: Record<string, unknown> }) => void;
    displayInOwnColumn?: boolean;
    freeRenewalParameters?: {
        lastActivationTimeIso: string;
        intervalMinutes: string;
    };
    icon?: ReactNode;
    onClose: () => void;
    triggerType: string;
    vacanciesIds: number[];
    vacanciesInfoToProlongate: unknown[];
}

// tempexp_30986_start
const REGULAR_RISE_INFO_URL = '/shards/employer/vacancies/auto_update/info';

declare global {
    interface FetcherPutApi {
        [REGULAR_RISE_INFO_URL]: {
            queryParams: void;
            body: {
                vacancyId: number[];
            };
            response: {
                forbidden: {
                    vacancies: Record<string, Record<string, string>>;
                };
            };
        };
    }
}
// tempexp_30986_end

const TrlKeys = {
    prolongate: 'vacancy.action.renewal',
};

const ProlongateAction: TranslatedComponent<ProlongateActionProps> = ({
    actionType,
    additionalAnalyticsParams,
    afterSuccessAction,
    Component,
    dispatchModal,
    displayInOwnColumn,
    freeRenewalParameters,
    icon,
    onClose,
    triggerType,
    trls,
    vacanciesIds,
    vacanciesInfoToProlongate,
}) => {
    // tempexp_30986_start
    const { isAnyRisePageExperimentEnabled, isRisePageDirectExperimentEnabled, sendCheck } = useShowRisePage();
    const getExperimentGroup = useExperimentGroupCheckFunction(sendCheck);
    // tempexp_30986_end

    const permissions = useSelector(({ permissions }) => permissions);
    const [isLoading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const breakpoint = useBreakpoint();
    const canShowHoverTip = [Breakpoint.M, Breakpoint.L].includes(breakpoint);

    const { lastActivationTimeIso, intervalMinutes } = freeRenewalParameters || {
        lastActivationTimeIso: null,
        intervalMinutes: null,
    };
    const isFreeRenewalAvailable = lastActivationTimeIso && intervalMinutes;

    const openModal = useCallback(
        // tempexp_30986_next_line param
        (showRegularButton: boolean) => {
            dispatchModal({
                type: ADD_MODAL,
                payload: {
                    modalType: MODAL_PROLONGATE,
                    data: {
                        afterSuccessAction,
                        freeRenewalParameters: {
                            lastActivationTimeIso,
                            intervalMinutes,
                        },
                        // tempexp_30986_next_line
                        showRegularButton,
                        vacanciesIds,
                        vacanciesInfoToProlongate,
                    },
                },
            });
            onClose && onClose();
        },
        [
            dispatchModal,
            vacanciesInfoToProlongate,
            vacanciesIds,
            afterSuccessAction,
            lastActivationTimeIso,
            intervalMinutes,
            onClose,
        ]
    );

    const loadProlongateInfo = useCallback(() => {
        // tempexp_30986_start
        getExperimentGroup(RISE_PAGE_DIRECT_EXPERIMENT);
        getExperimentGroup(RISE_PAGE_MODAL_EXPERIMENT);
        // tempexp_30986_end

        setLoading(true);

        // tempexp_30986_start
        if (isAnyRisePageExperimentEnabled) {
            dispatch(resetRiseValues());
        }
        // tempexp_30986_end

        return dispatch(fetchProlongateInfo({ vacanciesIds })).then(
            (prolongateInfo) => {
                setLoading(false);

                // tempexp_30986_start
                if (isAnyRisePageExperimentEnabled) {
                    const error = validateResponse(prolongateInfo, trls);
                    if (error) {
                        dispatch(setRiseFailValidate(error));
                        return;
                    }

                    if (isRisePageDirectExperimentEnabled) {
                        openBlankRisePage(vacanciesIds);
                        return;
                    }

                    let showRegularButton = false;
                    fetcher
                        .put(REGULAR_RISE_INFO_URL, {
                            vacancyId: vacanciesIds,
                        })
                        .then(({ data }) => {
                            showRegularButton = Object.keys(data.forbidden.vacancies).length !== vacanciesIds.length;
                        })
                        .finally(() => {
                            openModal(showRegularButton);
                        });
                } else {
                    // tempexp_30986_next_line param
                    openModal(false);
                }
                // tempexp_30986_end
            },
            () => {
                addNotification(vacancyActionErrorNotification);
                setLoading(false);
            }
        );
    }, [
        getExperimentGroup,
        isAnyRisePageExperimentEnabled,
        dispatch,
        vacanciesIds,
        openModal,
        trls,
        isRisePageDirectExperimentEnabled,
        addNotification,
    ]);

    return (
        <Component
            actionType={actionType}
            additionalAnalyticsParams={additionalAnalyticsParams}
            batchLength={vacanciesIds.length}
            displayInOwnColumn={displayInOwnColumn}
            icon={icon}
            isLoading={isLoading}
            isPermitted={permissions.includes(UPDATE_CREATE_PERMISSION)}
            onTrigger={loadProlongateInfo}
            triggerName="prolongate"
            triggerType={triggerType}
        >
            {isFreeRenewalAvailable && canShowHoverTip ? (
                <HoverTip
                    placement={TipPlacement.Bottom}
                    render={() => (
                        <FreeRenewalTip lastFreeRenewal={lastActivationTimeIso} freeRenewalInterval={intervalMinutes} />
                    )}
                    host={!process.env.LUX_SERVER ? document.body : null}
                >
                    <Text Element="span">{trls[TrlKeys.prolongate]}</Text>
                </HoverTip>
            ) : (
                trls[TrlKeys.prolongate]
            )}
        </Component>
    );
};

export default translation(ProlongateAction);
