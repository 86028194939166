import PropTypes from 'prop-types';

import vacancySuitableItemButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_suitable_item_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import urlParser from 'bloko/common/urlParser';

import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import Company from 'lux/components/SuitableVacancy/Company';
import Compensation from 'lux/components/SuitableVacancy/Compensation';

const SuitableVacancy = ({
    compensation,
    vacancyName,
    vacancyId,
    companyVisibleName,
    area,
    companyId,
    companyDepartment,
    renderIcon,
    renderAdditional,
    links,
    isRedesign,
}) => {
    const isAnonymous = useSelector((state) => state.userType) === UserType.Anonymous;
    const url = urlParser(links.desktop);
    const hhtmFromLabel = isAnonymous ? 'similar_vacancies_sidebar' : 'suitable_vacancies_sidebar';
    const vacancyViewId = useSelector((state) => state.vacancyView?.vacancyId);

    const onNameClick = () => {
        vacancySuitableItemButtonClick({
            hhtmSourceLabel: 'vacancy_side_block',
            vacancyId: vacancyViewId,
            vacancyTargetId: vacancyId,
        });
    };

    return (
        <div className="recommended-vacancies__item recommended-vacancies__item_full">
            <Text size={isRedesign ? TextSize.Large : undefined}>
                <div className={isRedesign ? undefined : 'vacancy-of-the-day'}>
                    {renderIcon && renderIcon()}
                    <BlokoLink
                        kind={LinkKind.Tertiary}
                        Element={Link}
                        to={url.href}
                        additionalQueryParams={{ hhtmFromLabel }}
                        target="_blank"
                        onClick={onNameClick}
                    >
                        <span className="vacancy-of-the-day__title">{vacancyName}</span>
                        <Compensation compensation={compensation} />
                    </BlokoLink>
                    <span
                        className={isRedesign ? 'vacancy-of-the-day-company-redesigned' : 'vacancy-of-the-day__company'}
                    >
                        <Company
                            visibleName={companyVisibleName}
                            id={companyId}
                            department={companyDepartment}
                            area={area}
                        />
                    </span>
                    {renderAdditional && renderAdditional()}
                </div>
            </Text>
        </div>
    );
};

SuitableVacancy.propTypes = {
    compensation: PropTypes.object,
    vacancyName: PropTypes.string.isRequired,
    companyVisibleName: PropTypes.string,
    companyId: PropTypes.number,
    vacancyId: PropTypes.number,
    companyDepartment: PropTypes.object,
    links: PropTypes.object,
    area: PropTypes.object,
    renderIcon: PropTypes.func,
    renderAdditional: PropTypes.func,
    isRedesign: PropTypes.bool,
};

export default SuitableVacancy;
