import { Fragment, useCallback, useEffect, useReducer, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper, ColumnsRow } from 'bloko/blocks/column';

import SendFloodlightConversion from 'HH/SendFloodlightConversion';
import AuctionBanner from 'lux/components/Auction/Banner';
import useAuctionCreationEnabled from 'lux/components/Auction/hooks/useAuctionCreationEnabled';
import EmployerReviewsWidgetBig from 'lux/components/EmployerReviews/WidgetBig';
import VacancyNotifications from 'lux/components/Notifications/VacancyView';
import RelatedVacancies from 'lux/components/RelatedVacancies';
import RelatedVacanciesLazy from 'lux/components/RelatedVacancies/LazyWrapper';
import VacancyImportantBanners from 'lux/components/VacancyImportantBanners';
import Modals, { modalReducer } from 'lux/components/VacancyModals';
import { CLOSE_MODAL } from 'lux/components/VacancyModals/constants';
import VacancyEmployerMainTitle from 'lux/components/VacancyView/VacancyEmployerMainTitle';
import VacancyKeyInfo from 'lux/components/VacancyView/VacancyKeyInfo';
import VacancySystemInfo from 'lux/components/VacancyView/VacancySystemInfo';
// tempexp_26879_nextline
import WantSame from 'lux/components/VacancyView/WantSame';
import useBranding from 'lux/components/VacancyView/hooks/useBranding';
import VacancyViewPropTypes from 'lux/components/VacancyView/propTypes';
import { useAddEventPixelsRu } from 'lux/hooks/useAddEventPixelsRu';
import { useDV360Analytics } from 'lux/hooks/useDV360Analytics';
import { CountryId } from 'lux/models/countryId';
import { UserType } from 'lux/models/userType';
import { VacancyViewTab } from 'lux/models/vacancyViewTab';
import { useSelector } from 'lux/modules/useSelector';

import VacancyBreadcrumbs from 'lux/pages/VacancyView/components/VacancyBreadcrumbs';
import VacancyEmployerSidebar from 'lux/pages/VacancyView/components/VacancyEmployerSidebar';
import VacancyHeader from 'lux/pages/VacancyView/components/VacancyHeader';
import VacancyTabPaneContent from 'lux/pages/VacancyView/components/VacancyTabPaneContent';
import VacancyTabs from 'lux/pages/VacancyView/components/VacancyTabs';

const VacancyViewEmployer = ({ vacancyView }) => {
    const { active: activeTab, tabs } = useSelector((state) => state.vacancyViewTab);
    const { isBranded, isConstructor } = useBranding();
    const isShowTabs = tabs.length > 1;

    const checkedVacancies = useRef({ [vacancyView.managerId]: [vacancyView.vacancyId] });
    const [modalData, dispatchModal] = useReducer(modalReducer, {});
    const handleCloseModal = useCallback(() => dispatchModal({ type: CLOSE_MODAL }), []);

    const isNewVacancy = useSelector(({ isNewVacancy }) => isNewVacancy);
    const userType = useSelector(({ userType }) => userType);
    const isTrustedEmployer = useSelector(
        (state) => state.employerInfo.isTrusted || state.vacancyView.company['@trusted']
    );
    const sendDV360Analytics = useDV360Analytics();
    const countryId = useSelector((state) => state.countryId);
    const addEventPixelsRu = useAddEventPixelsRu();
    const isVacancySpeedupExp = useSelector((state) => state.vacancyPageSpeedup);

    const auctionCreationEnabled = useAuctionCreationEnabled();

    useEffect(() => {
        if (isNewVacancy && userType === UserType.Employer) {
            Analytics.sendEvent('employer', 'vacancy-publish');
            addEventPixelsRu('B2B_VACANCY_COMPLETE');
            sendDV360Analytics('VACANCY_SHOWN');

            if (countryId === CountryId.Kazakhstan) {
                SendFloodlightConversion({ type: 'hh_kz0', cat: 'new_v0' });
            }
        }
    }, [isNewVacancy, userType, countryId, addEventPixelsRu, sendDV360Analytics]);

    const vacancyLayoutHeader = (
        <>
            {vacancyView?.systemInfo && (
                <ColumnsRow>
                    <Column xs="4" s="8" m="8" l="10">
                        <VacancyHeader
                            checkedVacancies={checkedVacancies}
                            dispatchModal={dispatchModal}
                            vacancyView={vacancyView}
                        />
                        <VSpacing default={26} />
                    </Column>
                </ColumnsRow>
            )}
            <Column xs="4" s="8" m="0" l="0">
                <VacancyImportantBanners
                    vacancyId={vacancyView.vacancyId}
                    vacancyName={vacancyView.name}
                    dispatchModal={dispatchModal}
                    marginBottom
                />
                {auctionCreationEnabled && <AuctionBanner />}
                <VSpacing default={26} />
            </Column>
            {isShowTabs && (
                <ColumnsRow>
                    <Column xs="4" s="8" m="8" l="10">
                        <VacancyTabs />
                        <VSpacing default={26} />
                    </Column>
                </ColumnsRow>
            )}
        </>
    );

    const backofficeRelatedVacancies = (
        <div className="vacancy-noprint">
            <Column xs="4" s="8" m="8" l="11">
                {isVacancySpeedupExp ? <RelatedVacanciesLazy /> : <RelatedVacancies />}
            </Column>
        </div>
    );

    const employerReviews = isTrustedEmployer && !isConstructor && (
        <Column xs="4" s="8" m="8" l="11">
            <EmployerReviewsWidgetBig />
        </Column>
    );

    const tabContent = (
        <VacancyTabPaneContent
            employerReviews={employerReviews}
            backofficeRelatedVacancies={backofficeRelatedVacancies}
        />
    );

    const titleAndInfo = (
        <>
            {vacancyView?.systemInfo && (
                <ColumnsRow>
                    <Column xs="4" s="8" m="8" l="10">
                        <VacancyEmployerMainTitle />
                        <VSpacing default={20} />
                        <VacancySystemInfo />
                        <VSpacing default={16} />
                        <VacancyKeyInfo />
                        <VSpacing default={20} />
                    </Column>
                </ColumnsRow>
            )}
        </>
    );

    return (
        <Fragment>
            <VacancyBreadcrumbs />
            <VSpacing default={2} />
            <VacancyNotifications dispatchModal={dispatchModal} />

            {isBranded ? (
                <>
                    <ColumnsWrapper>
                        <ColumnsRow>
                            <Column xs="4" s="8" m="9" l="11" container>
                                {titleAndInfo}
                                {vacancyLayoutHeader}
                                {tabContent}
                            </Column>
                            <Column xs="0" s="0" m="3" l="5">
                                <VacancyEmployerSidebar vacancyView={vacancyView} dispatchModal={dispatchModal} />
                            </Column>
                        </ColumnsRow>
                    </ColumnsWrapper>
                    <ColumnsWrapper>
                        <ColumnsRow>
                            <Column xs="4" s="8" m="0" l="0">
                                <VacancyEmployerSidebar vacancyView={vacancyView} dispatchModal={dispatchModal} />
                            </Column>
                            {activeTab === VacancyViewTab.Main && (
                                <>
                                    {employerReviews}
                                    {backofficeRelatedVacancies}
                                </>
                            )}
                        </ColumnsRow>
                        {/* tempexp_26879_nextline*/}
                        <WantSame keyPrefix="vacancyId" id={String(vacancyView.vacancyId)} />
                    </ColumnsWrapper>
                </>
            ) : (
                <ColumnsWrapper>
                    <ColumnsRow>
                        <Column xs="4" s="8" m="8" l="11" container>
                            {titleAndInfo}
                            {vacancyLayoutHeader}
                            {tabContent}
                        </Column>
                        <Column xs="4" s="8" m="4" l="5">
                            <VacancyEmployerSidebar vacancyView={vacancyView} dispatchModal={dispatchModal} />
                        </Column>
                    </ColumnsRow>
                </ColumnsWrapper>
            )}
            <Modals
                checkedVacancies={checkedVacancies.current}
                dispatchModal={dispatchModal}
                handleCloseModal={handleCloseModal}
                modalData={modalData}
            />
        </Fragment>
    );
};

VacancyViewEmployer.propTypes = {
    vacancyView: VacancyViewPropTypes.vacancyView,
};

export default VacancyViewEmployer;
