import { ReactNode } from 'react';

import { Text } from '@hh.ru/magritte-ui';
import { ArrowUpOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { RectangleChartPoint } from 'lux/pages/VacancyView/components/VacancyStatTab/Chart/RectangleChart/types';
import Color from 'lux/pages/VacancyView/components/VacancyStatTab/Chart/color';

import styles from './legend.less';

interface LegendItemProps {
    title: string;
    children: ReactNode;
}

const LegendItem = ({ title, children }: LegendItemProps) => (
    <div className={styles.legendItem}>
        {children}
        <Text>{title}</Text>
    </div>
);

interface PublicationLegendProps {
    data: RectangleChartPoint[];
    showProlongationIcon?: boolean;
}

const TrlKeys = {
    prolongation: 'statportal.pages.vacancyConversion.publication.prolongation',
    event: 'statportal.pages.vacancyConversion.publication.event',
};

const PublicationLegend: TranslatedComponent<PublicationLegendProps> = ({
    data,
    trls,
    showProlongationIcon = false,
}) => {
    const uniqPublications = data.reduce((acc, cur) => {
        acc[cur.title] = cur.color;
        return acc;
    }, {} as Record<string, Color>);

    return (
        <div className={styles.publicationLegend} data-qa="publication-legend">
            {Object.entries(uniqPublications).map(([publicationType, color]) => (
                <LegendItem title={publicationType} key={publicationType}>
                    <span
                        className={styles.legendItemIcon}
                        style={{
                            backgroundColor: color,
                        }}
                    />
                </LegendItem>
            ))}
            {showProlongationIcon && (
                <LegendItem title={trls[TrlKeys.prolongation]}>
                    <div className={styles.prolongationIcon}>
                        <ArrowUpOutlinedSize16 initial="contrast" />
                    </div>
                </LegendItem>
            )}
        </div>
    );
};

export default translation(PublicationLegend);
