import { useRef, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ConversionNumber from 'bloko/blocks/conversion';
import { H2Section } from 'bloko/blocks/header';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import NumberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import scrollToElement from 'Utils/ScrollToElement';
import RelatedVacanciesTitle from 'lux/components/RelatedVacancies/Title';
import { RELATED_VACANCIES_TYPES } from 'lux/components/RelatedVacancies/relatedVacanciesTypes';
import SuitableVacancy from 'lux/components/SuitableVacancy';
import translation from 'lux/components/translation';
import { NON_BREAKING_SPACE, NARROW_NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';

const RELATED_VACANCIES_SHOWN_COUNT = 2;
const SCROLL_OFFSET = -20;

const RelatedVacanciesPreview = ({ fullListRef, trls }) => {
    const {
        vacancies,
        resultsFound: vacanciesCount,
        type: relatedType,
    } = useSelector((state) => state.relatedVacancies);
    const vacanciesPreview = vacancies?.slice(0, RELATED_VACANCIES_SHOWN_COUNT);
    const stickyPlaceRef = useRef();
    const stickyElementRef = useRef();
    const dummyRef = useRef();
    const breakpoint = useBreakpoint(Breakpoint.L);
    const isL = breakpoint === Breakpoint.L;
    const [isVisible, setIsVisible] = useState(true);

    const updateMetrics = useCallback(() => {
        if (!isL || !fullListRef.current || !stickyElementRef.current || !stickyPlaceRef.current) {
            return;
        }
        const fullListTop = fullListRef.current.offsetTop;
        const stickyElementBottom = stickyPlaceRef.current.offsetTop + stickyElementRef.current.offsetHeight;
        const diff = fullListTop - stickyElementBottom;
        dummyRef.current.style.height = `${diff}px`;
        setIsVisible(diff > 0);
    }, [fullListRef, isL]);

    useEffect(() => {
        updateMetrics();
        const intervalId = setInterval(() => {
            updateMetrics();
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [updateMetrics]);

    const scrollToFullList = useCallback(() => {
        if (fullListRef?.current) {
            scrollToElement(fullListRef.current, {
                centered: false,
                topOffset: SCROLL_OFFSET,
            });
        }
    }, [fullListRef]);

    const isSuitable = relatedType === RELATED_VACANCIES_TYPES.SUITABLE;
    const vacanciesCountFormatted = NumberFormatter.format(String(vacanciesCount), {
        groupSeparator: NARROW_NON_BREAKING_SPACE,
    });

    if (!vacanciesPreview?.length || !isL) {
        return null;
    }

    return (
        <>
            <div ref={stickyPlaceRef} />
            <div
                className={classnames('related-vacancies-preview', {
                    'related-vacancies-preview_hidden': !isVisible,
                })}
                ref={stickyElementRef}
            >
                <H2Section Element="h3">
                    <RelatedVacanciesTitle type={relatedType} />
                </H2Section>
                <VSpacing base={2} />
                <div className="recommended-vacancies">
                    {vacanciesPreview.map((vacancy) => (
                        <SuitableVacancy
                            vacancyName={vacancy.name}
                            compensation={{
                                from: vacancy.compensation.from,
                                to: vacancy.compensation.to,
                                currencyCode: vacancy.compensation.currencyCode,
                            }}
                            companyVisibleName={vacancy.company.visibleName}
                            area={{ name: vacancy.area.name }}
                            companyId={Number(vacancy.company.id)}
                            vacancyId={vacancy.vacancyId}
                            links={{ desktop: `/vacancy/${vacancy.vacancyId}` }}
                            key={vacancy.vacancyId}
                            isRedesign
                        />
                    ))}
                </div>
                <div className="related-vacancies-preview-switch">
                    <Link appearance={LinkAppearance.Pseudo} onClick={scrollToFullList}>
                        {!isSuitable && vacanciesCountFormatted}
                        {!isSuitable && NON_BREAKING_SPACE}
                        <ConversionNumber
                            value={vacanciesCount}
                            format={(trl) => format(trl, { '{0}': vacanciesCountFormatted })}
                            one={trls[RelatedVacanciesPreview.trls.moreVacancies[relatedType].one]}
                            some={trls[RelatedVacanciesPreview.trls.moreVacancies[relatedType].some]}
                            many={trls[RelatedVacanciesPreview.trls.moreVacancies[relatedType].many]}
                            hasValue={false}
                        />
                    </Link>
                </div>
            </div>
            <div ref={dummyRef} />
        </>
    );
};

RelatedVacanciesPreview.propTypes = {
    fullListRef: PropTypes.object,
    trls: PropTypes.object,
};

RelatedVacanciesPreview.trls = {
    moreVacancies: {
        similar: {
            one: 'vacancy.similar.one',
            some: 'vacancy.similar.some',
            many: 'vacancy.similar.many',
        },
        suitable: {
            one: 'vacancy.suitable.more.one',
            some: 'vacancy.suitable.more.some',
            many: 'vacancy.suitable.more.many',
        },
    },
};

export default translation(RelatedVacanciesPreview);
