import { useState, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Card, Title, Text, VSpacing, TooltipHover } from '@hh.ru/magritte-ui';
import { ListShortOutlinedSize24, ListOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SegmentedControl from 'lux/components/SegmentedControl';
import VacancySearchItem from 'lux/components/VacancySearchItem';
import Source from 'lux/components/VacancySearchItem/types/Source';
import translation from 'lux/components/translation';
import { VacancySearchItem as IVacancySearchItem } from 'lux/models/vacancySearch/vacancySearchItem.types';

import { SegmentSize } from 'lux/pages/VacancyView/components/VacancyMainTab/SearchSnippet/constants';

import styles from './search-snippet.less';

const TrlKeys = {
    title: 'employer.vacancy.snippet.tag.title',
    description: 'employer.vacancy.snippet.card.description',
    tip: {
        short: 'employer.vacancy.snippet.tip.short',
        full: 'employer.vacancy.snippet.tip.full',
    },
};

interface SearchSnippetProps {
    vacancy: IVacancySearchItem;
    onSelectPane: (pane: SegmentSize) => void;
}

const SearchSnippet: TranslatedComponent<SearchSnippetProps> = ({ trls, vacancy, onSelectPane }) => {
    const [activePane, setActivePane] = useState(SegmentSize.Short);
    const shortSegmentSizeRef = useRef(null);
    const fullSegmentSizeRef = useRef(null);

    const SEGMENT_SECTION = {
        [SegmentSize.Short]: (
            <span ref={shortSegmentSizeRef}>
                <ListShortOutlinedSize24 />
            </span>
        ),
        [SegmentSize.Full]: (
            <span ref={fullSegmentSizeRef}>
                <ListOutlinedSize24 />
            </span>
        ),
    };

    const onSelect = (pane: SegmentSize) => {
        onSelectPane(pane);
        setActivePane(pane);
        Analytics.sendHHEventButtonClick(`vacancy_view_${pane}_segment_snippet_size_select`, {
            vacancyId: vacancy.vacancyId,
        });
    };

    const onShownSnippet = (element: HTMLDivElement) => {
        if (element) {
            Analytics.sendHHEventElementShown(element, {
                name: 'vacancy_view_search_snippet',
                branded: Boolean(vacancy.brandSnippet),
                vacancyId: vacancy.vacancyId,
            });
        }
    };

    const onSnippetClick = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_search_snippet_card', {
            branded: Boolean(vacancy.brandSnippet),
            vacancyId: vacancy.vacancyId,
        });
    };

    return (
        <>
            <Card showBorder stretched borderRadius={24} padding={24} paddingBottom={8}>
                <div className={styles.searchSnippetBox}>
                    <div>
                        <Title Element="h4" size="small" dataQaTitle="search-snippet-title">
                            {trls[TrlKeys.title]}
                        </Title>
                        <VSpacing default={12} />
                        <Text Element="span" typography="label-2-regular" style="secondary">
                            {trls[TrlKeys.description]}
                        </Text>
                    </div>
                    <div className={styles.searchSnippetControl}>
                        <SegmentedControl
                            active={activePane}
                            sections={SEGMENT_SECTION}
                            onSelect={onSelect}
                            size="medium"
                        />
                    </div>
                </div>
                <VSpacing default={24} />
                <div onClick={onSnippetClick}>
                    <div className={styles.searchSnippetCard} ref={onShownSnippet}>
                        <VacancySearchItem vacancy={vacancy} vacancySource={Source.EmployerVacancy} isXs={false} />
                    </div>
                </div>
            </Card>
            <TooltipHover direction="bottom" alignment="center" activatorRef={shortSegmentSizeRef}>
                {trls[TrlKeys.tip.short]}
            </TooltipHover>
            <TooltipHover direction="bottom" alignment="center" activatorRef={fullSegmentSizeRef}>
                {trls[TrlKeys.tip.full]}
            </TooltipHover>
        </>
    );
};

export default translation(SearchSnippet);
