import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { CheckCircleFilledSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notification from 'lux/components/Notifications/Notification';
import { NotificationProps } from 'lux/components/Notifications/Provider/types';
import translation from 'lux/components/translation';
import { resetRiseValues } from 'lux/models/employerVacanciesRise';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    aria: 'vacancy.rise.aria',
    goToBuy: 'vacancy.rise.goToBuy',
};
const PRICE_LIST_URL = '/price/publications';

const SnackbarRisePageExperimentNotification: TranslatedComponent<NotificationProps> = ({ trls, ...props }) => {
    const dispatch = useDispatch();
    const error = useSelector((state) => state.employerVacanciesRise?.error);
    const success = useSelector((state) => state.employerVacanciesRise?.success);

    const handleClose = () => {
        dispatch(resetRiseValues());
    };

    if (error) {
        return (
            <Notification
                addon={<MinusCircleFilledSize24 initial="negative" />}
                aria-label={trls[TrlKeys.aria]}
                onClose={handleClose}
                showClose
                onAction={() => {
                    dispatch(push(PRICE_LIST_URL));
                }}
                actionLabel={error.reason ? trls[TrlKeys.goToBuy] : undefined}
                {...props}
            >
                {error.message}
            </Notification>
        );
    }
    if (success) {
        return (
            <Notification
                addon={<CheckCircleFilledSize24 initial="positive" />}
                aria-label={trls[TrlKeys.aria]}
                onClose={handleClose}
                showClose
                {...props}
            >
                {success.message}
            </Notification>
        );
    }
    return null;
};

export default translation(SnackbarRisePageExperimentNotification);
