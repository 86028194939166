import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

const Company = ({ visibleName, id, department, area }) => {
    const areaContent = area ? `, ${area.name}` : null;
    const content = (
        <Fragment>
            {visibleName}
            {areaContent}
        </Fragment>
    );

    if (!id) {
        return content;
    }

    const departmentQuery = department && department['@code'] ? `?dpt=${department['@code']}` : '';

    return (
        <BlokoLink target="_blank" kind={LinkKind.Secondary} to={`/employer/${id}${departmentQuery}`} Element={Link}>
            {content}
        </BlokoLink>
    );
};

Company.propTypes = {
    visibleName: PropTypes.string.isRequired,
    id: PropTypes.number,
    department: PropTypes.object,
    area: PropTypes.object,
};

export default Company;
