import { useEffect, useRef } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Card, VSpacing } from '@hh.ru/magritte-ui';
import Link from '@hh.ru/redux-spa-middleware/lib/Link';
import { SalaryRange } from '@hh.ru/types-hh-microcore';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import { CanvacBannerCase, CompetitionLevel } from 'lux/models/canvacVacancySalaryData';
import { CurrencyType } from 'lux/models/currencies.types';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';
import formatToInt from 'lux/utils/formatToInt';

import styles from './vacancy-important-banners.less';

const TrlKeys = {
    salaryRange: {
        [SalaryRange.TooHigh]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.TOO_HIGH',
        [SalaryRange.High]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.HIGH',
        [SalaryRange.MatchMarket]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.MATCH_MARKET',
        [SalaryRange.Low]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.LOW',
        [SalaryRange.TooLow]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.TOO_LOW',
        [SalaryRange.TooWide]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.TOO_WIDE',
        [SalaryRange.Undefined]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.UNDEFINED',
        [SalaryRange.NotSpecified]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.NOT_SPECIFIED',
    },
    salaryRangeTitle: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange',
    salaryRangeTitle2: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.2',
    salaryRangeInterval: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.interval',
    competitionLevel: {
        [CompetitionLevel.High]: {
            name: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.HIGH',
            description: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.HIGH.description',
        },
        [CompetitionLevel.Medium]: {
            name: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.MEDIUM',
            description: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.MEDIUM.description',
        },
        [CompetitionLevel.Low]: {
            name: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.LOW',
            description: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.LOW.description',
        },
        [CompetitionLevel.Undefined]: {
            name: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.UNDEFINED',
            description: '',
        },
    },
    responsesTitle: 'employer.vacancy.VacancyImportantBanners.canvac.responses',
    responses: {
        one: 'statportal.competitorAnalysisForVacancy.conversion.responses.one',
        some: 'statportal.competitorAnalysisForVacancy.conversion.responses.some',
        many: 'statportal.competitorAnalysisForVacancy.conversion.responses.many',
    },
    responsesDescription: 'employer.vacancy.VacancyImportantBanners.canvac.responses.description',
    currency: {
        [CurrencyType.AZN]: 'AZN',
        [CurrencyType.BYR]: 'BYR',
        [CurrencyType.EUR]: 'EUR',
        [CurrencyType.KZT]: 'KZT',
        [CurrencyType.RUR]: 'RUR',
        [CurrencyType.UAH]: 'UAH',
        [CurrencyType.USD]: 'USD',
        [CurrencyType.UZS]: 'UZS',
        [CurrencyType.GEL]: 'GEL',
        [CurrencyType.KGS]: 'KGS',
    },
};

const COMPETITION_LEVEL_HIGHLIGHT = {
    [CompetitionLevel.High]: styles.highlightBad,
    [CompetitionLevel.Medium]: styles.highlightNormal,
    [CompetitionLevel.Low]: styles.highlightGood,
    [CompetitionLevel.Undefined]: undefined,
};

const SALARY_RANGE_HIGHLIGHT = {
    [SalaryRange.Undefined]: undefined,
    [SalaryRange.NotSpecified]: styles.highlightBad,
    [SalaryRange.TooWide]: styles.highlightNormal,
    [SalaryRange.TooLow]: styles.highlightBad,
    [SalaryRange.Low]: styles.highlightBad,
    [SalaryRange.MatchMarket]: styles.highlightGood,
    [SalaryRange.High]: styles.highlightGood,
    [SalaryRange.TooHigh]: styles.highlightGood,
};

const SALARY_RANGE_HIGHLIGHT_DOT = {
    [SalaryRange.Undefined]: undefined,
    [SalaryRange.NotSpecified]: styles.highlightedIntervalBad,
    [SalaryRange.TooWide]: styles.highlightedIntervalNormal,
    [SalaryRange.TooLow]: styles.highlightedIntervalBad,
    [SalaryRange.Low]: styles.highlightedIntervalBad,
    [SalaryRange.MatchMarket]: styles.highlightedIntervalGood,
    [SalaryRange.High]: styles.highlightedIntervalGood,
    [SalaryRange.TooHigh]: styles.highlightedIntervalGood,
};

const CanvacBanner: TranslatedComponent<{ vacancyId: string }> = ({ vacancyId, trls }) => {
    const ref = useRef<HTMLDivElement>(null);

    const canvacVacancySalaryData = useSelector((state) => state.canvacVacancySalaryData);
    const canvacBannerCase = canvacVacancySalaryData?.canvacBannerCase;

    useEffect(() => {
        if (ref.current && canvacBannerCase) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'important_banner_canvac',
                vacancyId,
                case: canvacBannerCase,
            });
        }
    }, [canvacBannerCase, vacancyId]);

    if (!canvacVacancySalaryData || !canvacVacancySalaryData.competitionLevel) {
        return null;
    }
    const { marketRange, competitionLevel, salaryRange, bottomResponseCount, upperResponseCount } =
        canvacVacancySalaryData;

    if (canvacBannerCase === CanvacBannerCase.Salary) {
        return (
            <Link
                to={`/employer/statistics/competitor_analysis/vacancy/${String(vacancyId)}`}
                target="_blank"
                onClick={() => {
                    Analytics.sendHHEventButtonClick('important_banner_canvac_button', {
                        vacancyId,
                        case: canvacBannerCase,
                    });
                }}
            >
                <Card
                    borderRadius={12}
                    padding={12}
                    stretched
                    actionCard
                    showShadowOnHover
                    data-qa="vacancy-important-banner-canvac"
                >
                    <div className={styles.banner} ref={ref}>
                        <div className={styles.canvacBannerTitle}>
                            {salaryRange === SalaryRange.TooWide
                                ? trls[TrlKeys.salaryRangeTitle2]
                                : trls[TrlKeys.salaryRangeTitle]}
                            {salaryRange === SalaryRange.TooWide || salaryRange === SalaryRange.NotSpecified
                                ? ' '
                                : ' — '}
                            <span className={classnames(styles.highlight, SALARY_RANGE_HIGHLIGHT[salaryRange])}>
                                {trls[TrlKeys.salaryRange[salaryRange]]}
                            </span>
                        </div>
                        <VSpacing default={6} />
                        <div className={SALARY_RANGE_HIGHLIGHT_DOT[salaryRange]}>
                            {trls[TrlKeys.salaryRangeInterval]}
                            {': '}
                            <span className={styles.interval}>
                                {formatToInt(marketRange.bottom)}
                                {NON_BREAKING_SPACE}
                                {'–'}
                                {NON_BREAKING_SPACE}
                                {formatToInt(marketRange.upper)} {trls[TrlKeys.currency[marketRange.currency]]}
                            </span>
                        </div>
                    </div>
                </Card>
            </Link>
        );
    }

    if (canvacBannerCase === CanvacBannerCase.CompetitionLevel) {
        return (
            <Link
                to={
                    `/employer/statistics/competitor_analysis/vacancy/${String(vacancyId)}` +
                    '?activeTab=competitorsAnalysis'
                }
                target="_blank"
                onClick={() => {
                    Analytics.sendHHEventButtonClick('important_banner_canvac_button', {
                        vacancyId,
                        case: canvacBannerCase,
                    });
                }}
            >
                <Card
                    borderRadius={12}
                    padding={12}
                    stretched
                    actionCard
                    showShadowOnHover
                    data-qa="vacancy-important-banner-canvac"
                >
                    <div className={styles.banner} ref={ref}>
                        <div className={styles.canvacBannerTitle}>
                            <span className={COMPETITION_LEVEL_HIGHLIGHT[competitionLevel]}>
                                {trls[TrlKeys.competitionLevel[competitionLevel].name]}
                            </span>
                        </div>
                        <VSpacing default={6} />
                        {trls[TrlKeys.competitionLevel[competitionLevel].description]}
                    </div>
                </Card>
            </Link>
        );
    }

    if (canvacBannerCase === CanvacBannerCase.Responses) {
        return (
            <Link
                to={
                    `/employer/statistics/competitor_analysis/vacancy/${String(vacancyId)}` +
                    '?activeTab=competitorsAnalysis'
                }
                target="_blank"
                onClick={() => {
                    Analytics.sendHHEventButtonClick('important_banner_canvac_button', {
                        vacancyId,
                        case: canvacBannerCase,
                    });
                }}
            >
                <Card
                    borderRadius={12}
                    padding={12}
                    stretched
                    actionCard
                    showShadowOnHover
                    data-qa="vacancy-important-banner-canvac"
                >
                    <div className={styles.banner} ref={ref}>
                        <div className={styles.canvacBannerTitle}>
                            {formatToReactComponent(trls[TrlKeys.responsesTitle], {
                                '{0}': (
                                    <span className={styles.interval}>
                                        {bottomResponseCount !== upperResponseCount ? (
                                            <>{formatToInt(bottomResponseCount)} – </>
                                        ) : null}
                                        {formatToInt(upperResponseCount)}{' '}
                                        <ConversionNumber
                                            value={upperResponseCount}
                                            hasValue={false}
                                            one={trls[TrlKeys.responses.one]}
                                            some={trls[TrlKeys.responses.some]}
                                            many={trls[TrlKeys.responses.many]}
                                            zero={trls[TrlKeys.responses.many]}
                                        />
                                    </span>
                                ),
                            })}
                        </div>
                        <VSpacing default={6} />
                        {trls[TrlKeys.responsesDescription]}
                    </div>
                </Card>
            </Link>
        );
    }

    return null;
};

export default translation(CanvacBanner);
