import { ReactNode, FC } from 'react';
import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import styles from './card.less';

interface CardProps {
    title: ReactNode;
    children: ReactNode;
    clickable?: boolean;
    to?: string;
    onClickLinkCallback?: () => void;
}

interface CardTitleProps {
    icon: ReactNode;
    text: string | ReactNode;
    iconRight?: ReactNode;
}

export const CardTitle: FC<CardTitleProps> = ({ icon, text, iconRight }) => {
    return (
        <div className={styles.cardHeading}>
            <span className={styles.cardTitle}>
                {icon}
                <Text Element="span" style="secondary">
                    {text}
                </Text>
            </span>
            {iconRight && <span className={styles.cardTitleIconRight}>{iconRight}</span>}
        </div>
    );
};

const Card: FC<CardProps> = ({ children, title, clickable, to, onClickLinkCallback }) => {
    const cardContent = (
        <div className={styles.cardContent}>
            {title}
            {children}
        </div>
    );

    const onClickLink = () => {
        onClickLinkCallback?.();
    };

    const clickableContent = (
        <Link to={to ?? '/'} target="_blank" onClick={onClickLink}>
            {cardContent}
        </Link>
    );

    return (
        <div className={classnames(styles.card, { [styles.cardClickable]: clickable })}>
            {clickable ? clickableContent : cardContent}
        </div>
    );
};

export default Card;
