import { createContext, RefObject } from 'react';
import { scaleTime, scaleLinear } from 'd3-scale';

import { LineChart } from 'lux/pages/VacancyView/components/VacancyStatTab/Chart/types';

interface ChartContextType {
    chartData: LineChart;
    width: number;
    height: number;
    chartDimension: number;
    containerRef: RefObject<HTMLDivElement>;
}

const ChartContext = createContext<ChartContextType>({
    chartData: { data: [], xAxis: scaleTime([0]), yAxis: scaleLinear([0]), linePath: null, areaPath: null },
    width: 0,
    height: 0,
    chartDimension: 0,
    containerRef: { current: null },
});

export default ChartContext;
