import { Fragment, useEffect, useMemo, useState } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';
import urlParser from 'bloko/common/urlParser';

import { VACANCY_CREATE_GET_URL } from 'lux/components/VacancyCreate/utils';
import translation from 'lux/components/translation';
import useUrl from 'lux/hooks/useUrl';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    DraftLoadOk: {
        message: 'employer.newVacancy.draftLoad.ok',
        clear: 'employer.newVacancy.loaded.clear',
    },
    DraftLoadError: { message: 'employer.newVacancy.draftLoad.error' },
    TemplateLoadOk: {
        message: 'employer.newVacancy.templateLoad.ok',
        clear: 'employer.newVacancy.loaded.clear',
    },
    TemplateLoadError: { message: 'employer.newVacancy.templateLoad.error' },
    VacancySubmitGenericError: { message: 'employer.VacancyCreate.vacancySubmitGenericError' },
    DraftSaveOk: { message: 'vacancy.create.autoSave.vacancySaved' },
    DraftSaveError: { message: 'vacancy.create.autoSave.vacancySavingError' },
    VacancySubmitDuplicateError: {
        messagePre: 'employer.VacancyCreate.vacancySubmitDuplicateError.message.pre',
        messageLink: 'employer.VacancyCreate.vacancySubmitDuplicateError.message.link',
        messagePost: 'employer.VacancyCreate.vacancySubmitDuplicateError.message.post',
        title: 'employer.VacancyCreate.vacancySubmitDuplicateError.title',
    },
};

const autoCloseProps = {
    autoClose: true,
    autoCloseDelay: 30 * 1000,
};

const useRemoveUponLeavingPage = (removeNotificationCallback: () => void, url: string) =>
    useEffect(() => {
        if (url && !url.includes(VACANCY_CREATE_GET_URL)) {
            removeNotificationCallback();
        }
    }, [removeNotificationCallback, url]);

const DraftLoadOk: TranslatedComponent<{ removeNotification: () => void }> = ({ trls, removeNotification }) => {
    const url = useSelector(({ router: { location } }) => location.pathname + location.search);

    useRemoveUponLeavingPage(removeNotification, url);
    const urlWithoutDraft = useMemo(() => {
        const parsedUrl = urlParser(url);
        parsedUrl.params.draftId = [];
        return parsedUrl.href;
    }, [url]);
    return (
        <Fragment>
            {trls[TrlKeys.DraftLoadOk.message]}
            <NotificationFooter>
                <BlokoLink
                    disableVisited
                    Element={Link}
                    onClick={removeNotification}
                    to={urlWithoutDraft}
                    cancelScrollCorrection
                >
                    {` ${trls[TrlKeys.DraftLoadOk.clear]}`}
                </BlokoLink>
            </NotificationFooter>
        </Fragment>
    );
};

export const draftLoadOk = {
    Element: translation(DraftLoadOk),
    kind: 'ok',
    ...autoCloseProps,
};

const DraftLoadError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.DraftLoadError.message]}</>;
export const draftLoadError = {
    Element: translation(DraftLoadError),
    kind: 'error',
    ...autoCloseProps,
};

const TemplateLoadOk: TranslatedComponent<{ removeNotification: () => void }> = ({ trls, removeNotification }) => {
    const url = useUrl();

    useRemoveUponLeavingPage(removeNotification, url);
    const templateName = useSelector(
        ({ vacancyCreateTemplates, vacancyCreateTemplateId }) =>
            vacancyCreateTemplates && vacancyCreateTemplates.find(({ id }) => id === vacancyCreateTemplateId)?.name
    );

    const preparedTemplateName = useSelector(
        ({ preparedVacancyTemplates, vacancyCreatePreparedTemplateId }) =>
            preparedVacancyTemplates &&
            preparedVacancyTemplates.find(({ id }) => id === vacancyCreatePreparedTemplateId)?.name
    );

    // Имя шаблона не должно меняться в нотификации после маунта
    const [name] = useState(templateName || preparedTemplateName || '');

    const urlWithoutTemplate = useMemo(() => {
        const parsedUrl = urlParser(url);
        parsedUrl.params.templateId = [];
        parsedUrl.params.preparedTemplateId = [];
        return parsedUrl.href;
    }, [url]);
    return (
        <Fragment>
            {format(trls[TrlKeys.TemplateLoadOk.message], { '{name}': name.trim().replace(/\s/g, NON_BREAKING_SPACE) })}
            <NotificationFooter>
                <BlokoLink
                    disableVisited
                    Element={Link}
                    onClick={removeNotification}
                    to={urlWithoutTemplate}
                    cancelScrollCorrection
                >
                    {` ${trls[TrlKeys.TemplateLoadOk.clear]}`}
                </BlokoLink>
            </NotificationFooter>
        </Fragment>
    );
};

export const templateLoadOk = {
    Element: translation(TemplateLoadOk),
    kind: 'ok',
    ...autoCloseProps,
};

const TemplateLoadError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.TemplateLoadError.message]}</>;

export const templateLoadError = {
    Element: translation(TemplateLoadError),
    kind: 'error',
    ...autoCloseProps,
};

const VacancySubmitGenericError: TranslatedComponent = ({ trls }) => (
    <>{trls[TrlKeys.VacancySubmitGenericError.message]}</>
);
export const vacancySubmitGenericError = {
    Element: translation(VacancySubmitGenericError),
    kind: 'error',
    autoClose: true,
};

const DraftSaveOk: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.DraftSaveOk.message]}</>;
export const draftSaveOk = {
    Element: translation(DraftSaveOk),
    kind: 'ok',
    autoClose: true,
};

const DraftSaveError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.DraftSaveError.message]}</>;
export const draftSaveError = {
    Element: translation(DraftSaveError),
    kind: 'error',
    autoClose: true,
};

const VacancySubmitDuplicateError: TranslatedComponent = ({ trls }) => (
    <Fragment>
        <NotificationHeading>{trls[TrlKeys.VacancySubmitDuplicateError.title]}</NotificationHeading>
        <div>
            {trls[TrlKeys.VacancySubmitDuplicateError.messagePre]}{' '}
            <BlokoLink disableVisited Element={Link} to="/employer/vacancies" target="_blank">
                {trls[TrlKeys.VacancySubmitDuplicateError.messageLink]}
            </BlokoLink>{' '}
            {trls[TrlKeys.VacancySubmitDuplicateError.messagePost]}
        </div>
    </Fragment>
);
export const vacancySubmitDuplicateError = {
    Element: translation(VacancySubmitDuplicateError),
    kind: 'error',
    ...autoCloseProps,
};
