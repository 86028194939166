import { Dispatch, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import Analytics from '@hh.ru/analytics-js';
import { Card, HSpacing } from '@hh.ru/magritte-ui';
import { AstrostarOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { ADD_MODAL, MODAL_VACANCY_ADVICE } from 'lux/components/VacancyModals/constants';
import translation from 'lux/components/translation';
import useExperiment from 'lux/hooks/useExperiment';
import { MarketRange } from 'lux/models/canvacVacancySalaryData';
import { VacancyAdvice as Advice } from 'lux/models/vacancy/vacancyAdvices.types';
import { useSelector } from 'lux/modules/useSelector';

import styles from './vacancy-important-banners.less';

const TrlKeys = {
    adviceCounter: {
        one: 'employer.myVacancies.advice.count.one',
        some: 'employer.myVacancies.advice.count.some',
        many: 'employer.myVacancies.advice.count.many',
    },
    adviceTitle: 'employer.vacancy.VacancyImportantBanners.advice',
};

const setPreparedAdvice = makeSetStoreField('preparedAdvice');

interface AdviceBannerProps {
    vacancyId: string;
    vacancyName: string;
    vacancyAreaName: string;
    dispatchModal: Dispatch<AnyAction>;
}

const AdviceBanner: TranslatedComponent<AdviceBannerProps> = ({
    dispatchModal,
    vacancyId,
    vacancyName,
    vacancyAreaName,
    trls,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const canvacVacancySalaryData = useSelector((state) => state.canvacVacancySalaryData);
    const advice = useSelector((state) => state.preparedAdvice);
    // tempexp_31119_next_line
    const adviceWithStatistics = useSelector((state) => state.preparedAdviceWithStatistics);
    const dispatch = useDispatch();
    const tagsAdviceModalExp = useExperiment('tags_advice_modal');

    const currentAdvices = tagsAdviceModalExp ? adviceWithStatistics.map((item) => item.advice) : advice;

    useEffect(() => {
        if (ref.current) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'important_banner_advice',
                vacancyId,
            });
        }
    }, [vacancyId]);

    if (!canvacVacancySalaryData || !canvacVacancySalaryData.marketRange || !currentAdvices.length) {
        return null;
    }
    const marketSalary: MarketRange = canvacVacancySalaryData.marketRange;

    const setAdvice = (advice: Advice[]) => dispatch(setPreparedAdvice(advice));

    const openModal = () => {
        Analytics.sendHHEventButtonClick('important_banner_advice_button', {
            vacancyId,
        });

        dispatchModal({
            type: ADD_MODAL,
            payload: {
                modalType: MODAL_VACANCY_ADVICE,
                data: {
                    vacancyId,
                    vacancyName,
                    vacancyAreaName,
                    advice: currentAdvices,
                    // tempexp_31119_next_line
                    adviceWithStatistics,
                    setAdvice,
                    marketSalary: {
                        from: marketSalary.bottom,
                        to: marketSalary.upper,
                        currency: marketSalary.currency,
                    },
                },
            },
        });
    };

    return (
        <>
            <Card
                borderRadius={12}
                padding={12}
                stretched
                showShadowOnHover
                actionCard
                onClick={openModal}
                data-qa="vacancy-important-banner-advice"
            >
                <div className={styles.adviceBanner} ref={ref}>
                    <AstrostarOutlinedSize16 initial="special" />
                    <HSpacing default={8} />
                    <span>
                        {trls[TrlKeys.adviceTitle]}
                        {': '}
                        <Conversion
                            value={currentAdvices.length}
                            one={trls[TrlKeys.adviceCounter.one]}
                            some={trls[TrlKeys.adviceCounter.some]}
                            many={trls[TrlKeys.adviceCounter.many]}
                        />
                    </span>
                </div>
            </Card>
        </>
    );
};

export default translation(AdviceBanner);
