import { MouseEvent, ReactElement, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Tooltip, Button, HSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';
import TooltipOnboardingContent from 'lux/pages/VacancyView/components/TooltipOnboardingContent';

interface TooltipHistoryTabProps {
    children: ReactElement;
    show: boolean;
    onBack: () => void;
    onClose: () => void;
}

const TrlKeys = {
    title: 'employer.vacancy.tab.history.tooltip.title',
    text: 'employer.vacancy.tab.history.tooltip.text',
    buttons: {
        ok: 'Clear',
        back: 'employer.vacancy.tab.history.tooltip.button.back',
    },
};

const TooltipHistoryTab: TranslatedComponent<TooltipHistoryTabProps> = ({ trls, children, show, onBack, onClose }) => {
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const sentRef = useRef(false);
    const activatorRef = useRef<HTMLSpanElement>(null);

    const hideTooltip = () => {
        onClose();
        Analytics.sendHHEventButtonClick('vacancy_view_history_tab_onboarding_ok', { vacancyId });
    };

    const onCloseHandler = (event: MouseEvent) => {
        event.stopPropagation();
        hideTooltip();
        Analytics.sendHHEventButtonClick('vacancy_view_history_tab_tip_close', { vacancyId });
    };

    const onBackHandler = (event: MouseEvent) => {
        event.stopPropagation();
        onBack();
        Analytics.sendHHEventButtonClick('vacancy_view_history_tab_tip_back', { vacancyId });
    };

    const onShownHandler = (element: HTMLDivElement) => {
        if (!sentRef.current) {
            Analytics.sendHHEventElementShown(element, {
                name: 'vacancy_view_history_tab_tip',
                vacancyId,
            });
            sentRef.current = true;
        }
    };

    return (
        <>
            <span ref={activatorRef}>{children}</span>
            <Tooltip
                visible={show}
                activatorRef={activatorRef}
                closeByClickOutside={false}
                direction="right"
                alignment="top"
            >
                <TooltipOnboardingContent
                    title={trls[TrlKeys.title]}
                    text={trls[TrlKeys.text]}
                    onClose={onCloseHandler}
                    onShown={onShownHandler}
                    actions={
                        <HSpacingContainer default={12}>
                            <Button style="contrast" mode="secondary" onClick={hideTooltip}>
                                {trls[TrlKeys.buttons.ok]}
                            </Button>
                            <Button style="contrast" mode="tertiary" onClick={onBackHandler}>
                                {trls[TrlKeys.buttons.back]}
                            </Button>
                        </HSpacingContainer>
                    }
                />
            </Tooltip>
        </>
    );
};

export default translation(TooltipHistoryTab);
