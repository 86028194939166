import { useRef } from 'react';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { HSpacing, Tag, Text, TooltipHover } from '@hh.ru/magritte-ui';
import { ClockOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { toDate } from 'bloko/blocks/calendar/datesHelper';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import HumanDate from 'lux/components/HumanDate';
import translation from 'lux/components/translation';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';

import SystemInfoItem, { SystemInfoItemColor } from 'lux/components/VacancyView/VacancySystemInfo/SystemInfoItem';
import { DAYS_TO_EXPIRES } from 'lux/components/VacancyView/VacancySystemInfo/constants';

import styles from './vacancy-system-info.less';

const TrlKeys = {
    archivedFrom: 'vacancy.view.archived.from',
    published: 'employer.vacancy.changesHistory.publish',
    expires: 'employer.vacancy.systemInfo.expiresDate',
    freeRestoreDaysLeft: 'employer.vacancy.archive.freeRestoreDaysLeft',
    days: {
        one: 'employer.vacancies.archive.freeRestore.days.one',
        some: 'employer.vacancies.archive.freeRestore.days.some',
        many: 'employer.vacancies.archive.freeRestore.days.many',
        zero: 'employer.vacancy.systemInfo.expires.days.zero',
    },
    autoProlongationPlannedBy: 'employer.vacancy.autoProlongation.plannedBy',
};

const VacancySystemInfo: TranslatedComponent = ({ trls }) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    const vacancyView = useSelector(({ vacancyView }) => vacancyView);
    const freeRestoreDays = useSelector((state) => state.vacancyInternalInfo?.freeRestoreDays);
    const { systemInfo, validThroughTime } = vacancyView;

    const isArchived = vacancyView.status.archived;

    const days =
        typeof systemInfo?.expirationTime === 'string'
            ? differenceInCalendarDays(toDate(systemInfo.expirationTime), Date.now())
            : -1;
    const expiresSoon = days <= DAYS_TO_EXPIRES;
    const expireTime = systemInfo?.expirationTime;

    const isShowExpireInfo = systemInfo?.expirationTime && days !== -1 && !isArchived;

    const freeRestoreInfo =
        freeRestoreDays && freeRestoreDays > 0 ? (
            <>
                {NON_BREAKING_SPACE}
                {formatToReactComponent(trls[TrlKeys.freeRestoreDaysLeft], {
                    '{0}': (
                        <Conversion
                            value={freeRestoreDays}
                            one={trls[TrlKeys.days.one]}
                            some={trls[TrlKeys.days.some]}
                            many={trls[TrlKeys.days.many]}
                        />
                    ),
                })}
            </>
        ) : null;

    const availableVacancyActions = useSelector((state) => state.availableVacancyActions);
    const isAutoProlongated = availableVacancyActions?.disableAutoProlongation;
    const isPublicationRequired = useSelector((state) => state.autoprolongation?.isPublicationRequired);

    return (
        <Text typography="label-3-regular">
            <div className={styles.vacancySystemInfo}>
                <Tag>
                    <span data-qa="vacancy-info-publication-type">
                        {vacancyView.vacancyProperties?.converted?.shortcut?.typeTrl}
                    </span>
                    {freeRestoreInfo}
                </Tag>
                {expireTime && isShowExpireInfo && (
                    <SystemInfoItem
                        text={trls[TrlKeys.expires]}
                        color={expiresSoon ? SystemInfoItemColor.Warning : SystemInfoItemColor.Default}
                        dataQa="vacancy-info-expire"
                    >
                        {formatDate(expireTime, 'dd.MM')}
                        <HSpacing default={6} />
                        {isAutoProlongated && !!expireTime && (
                            <>
                                <span
                                    ref={activatorRef}
                                    className={styles.vacancySystemInfoExpiresAutoProlongationIcon}
                                    data-qa="autoprolongation-icon"
                                >
                                    <ClockOutlinedSize16 initial={isPublicationRequired ? 'negative' : 'positive'} />
                                </span>
                                <TooltipHover direction="bottom" alignment="left" activatorRef={activatorRef}>
                                    {trls[TrlKeys.autoProlongationPlannedBy]}
                                    <br />
                                    {formatDate(expireTime, 'dd\u00a0MMMM y')}
                                </TooltipHover>
                            </>
                        )}
                    </SystemInfoItem>
                )}
                {isArchived && validThroughTime && (
                    <SystemInfoItem
                        text={trls[TrlKeys.archivedFrom]}
                        color={SystemInfoItemColor.Archive}
                        dataQa="vacancy-info-archived"
                    >
                        <HumanDate date={validThroughTime} />
                    </SystemInfoItem>
                )}
            </div>
        </Text>
    );
};

export default translation(VacancySystemInfo);
