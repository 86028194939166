import { FC, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import { BasicTrigger, TriggerType } from 'lux/components/ControlsTrigger';
import translation from 'lux/components/translation';
import fetcher from 'lux/modules/fetcher';

const TrlKeys = {
    restoreToArchive: 'employer.vacancyesArchive.backToArchive',
};

interface RestoreToArchiveActionProps {
    Component: FC<BasicTrigger>;
    triggerType: TriggerType;
    vacancyId: number;
    icon: ReactNode;
}

const RestoreToArchiveAction: TranslatedComponent<RestoreToArchiveActionProps> = ({
    Component,
    triggerType,
    trls,
    vacancyId,
    icon,
}) => {
    const dispatch = useDispatch();

    const onTrigger = async () => {
        const params = { action: 'makeVacancyVisible', vacancyId };
        try {
            await fetcher.get('/employer/vacancy-visibility', { params });
        } catch (error) {
            return;
        }
        const url = urlParser(window.location.href);
        url.params.makeVacancyVisible = 'true';
        dispatch(push(url.href));
    };

    return (
        <Component
            isPermitted
            onTrigger={onTrigger}
            triggerName="restoreToArchive"
            triggerType={triggerType}
            icon={icon}
        >
            {trls[TrlKeys.restoreToArchive]}
        </Component>
    );
};

export default translation(RestoreToArchiveAction);
