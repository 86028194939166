import { FC, ReactNode } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { updateUrl } from 'Modules/url';
import { BasicTrigger, TriggerType } from 'lux/components/ControlsTrigger';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    applicants: 'applicants',
};

interface ResponsesLinkActionProps {
    Component: FC<BasicTrigger>;
    triggerType: TriggerType;
    vacancyId: number;
    icon: ReactNode;
}

const RESPONSES_LINK = '/employer/vacancyresponses';

const ResponsesLinkAction: TranslatedComponent<ResponsesLinkActionProps> = ({
    Component,
    vacancyId,
    triggerType,
    trls,
}) => {
    const negotiationsCount = useSelector((state) => state.employerNegotiations.count);
    const responsesUrl = updateUrl(RESPONSES_LINK, { vacancyId });
    const triggerName = 'responses-link';

    return (
        <Component
            counter={negotiationsCount ? String(negotiationsCount) : undefined}
            triggerName={triggerName}
            triggerType={triggerType}
            triggerHref={responsesUrl}
        >
            {trls[TrlKeys.applicants]}
        </Component>
    );
};

export default translation(ResponsesLinkAction);
