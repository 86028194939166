import { FC } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BasicTrigger, ControlsTriggerActionType, TriggerType } from 'lux/components/ControlsTrigger';
import translation from 'lux/components/translation';
import paths from 'lux/modules/routePaths';

interface PfpTopupActionProps {
    Component: FC<BasicTrigger & { displayInOwnColumn: boolean }>;
    displayInOwnColumn: boolean;
    hhtmFromLabel: string;
    icon?: React.ReactNode;
    triggerType: TriggerType;
}

const TrlKeys = {
    deposit: 'pfp.balanceMessage.deposit',
};

const PfpTopupAction: TranslatedComponent<PfpTopupActionProps> = ({
    Component,
    displayInOwnColumn,
    hhtmFromLabel,
    icon,
    triggerType,
    trls,
}) => {
    return (
        <Component
            triggerName="pfp-topup"
            triggerType={triggerType}
            actionType={ControlsTriggerActionType.LIGHT}
            triggerHref={`${paths.pricePayForPerformance}?hhtmFromLabel=${hhtmFromLabel}`}
            displayInOwnColumn={displayInOwnColumn}
            icon={icon}
            isPermitted
        >
            {trls[TrlKeys.deposit]}
        </Component>
    );
};

export default translation(PfpTopupAction);
