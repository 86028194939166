import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    message: 'employer.service.message.fail',
};

const ErrorNotification: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.message]}</>;

export default {
    Element: translation(ErrorNotification),
    kind: 'error',
    autoClose: true,
};
