import PropTypes from 'prop-types';

import Compensation from 'lux/components/Compensation';

const SuitableVacancyCompensation = ({ compensation }) => {
    if (!compensation) {
        return null;
    }

    return (
        <div className="vacancy-of-the-day__salary">
            <Compensation {...compensation} />
        </div>
    );
};

SuitableVacancyCompensation.propTypes = {
    compensation: PropTypes.object,
};

export default SuitableVacancyCompensation;
