import { FC, ComponentType } from 'react';

import ChartContainer, { ChartContainerProps } from 'lux/components/VacancyView/SalaryMarketRangeChart/ChartContainer';
import { ChartProps } from 'lux/components/VacancyView/SalaryMarketRangeChart/types';

type WithChartContainerProps = ChartProps & ChartContainerProps;

const withChartContainer =
    (Component: ComponentType<ChartProps>): FC<WithChartContainerProps> =>
    ({ data, height }: WithChartContainerProps) =>
        (
            <ChartContainer height={height}>
                <Component data={data} />
            </ChartContainer>
        );

export default withChartContainer;
