import { FC } from 'react';

import { VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';

import { useSelector } from 'lux/modules/useSelector';

import ChartLegendInfo from 'lux/components/VacancyView/SalaryMarketRangeChart/ChartLegendInfo';
import MarketRangeText from 'lux/components/VacancyView/SalaryMarketRangeChart/MarketRangeText';
import SalaryRangeChart from 'lux/components/VacancyView/SalaryMarketRangeChart/SalaryRangeChart';
import TicksXAxis from 'lux/components/VacancyView/SalaryMarketRangeChart/TicksXAxis';
import { CHART_HEIGHT } from 'lux/components/VacancyView/SalaryMarketRangeChart/constants';
import { MarketRange } from 'lux/components/VacancyView/SalaryMarketRangeChart/types';

import styles from './salary-market-range-chart.less';

const SalaryMarketRangeChart: FC = () => {
    const marketRange = useSelector((state) => state.canvacVacancySalaryData?.marketRange);
    const { compensation } = useSelector((state) => state.vacancyView);

    if (!marketRange) {
        return null;
    }

    const data: MarketRange = [marketRange.minimal, marketRange.bottom, marketRange.upper, marketRange.maximal];

    return (
        <>
            <VSpacingContainer default={12}>
                <MarketRangeText />
                <div className={styles.chartBackground}>
                    <SalaryRangeChart data={data} height={CHART_HEIGHT} />
                </div>
                <div className={styles.chartTicksWrapper}>
                    <TicksXAxis data={data} height={24} />
                </div>
            </VSpacingContainer>
            <VSpacing default={24} />
            {(compensation?.from || compensation?.to) && <ChartLegendInfo />}
        </>
    );
};

export default SalaryMarketRangeChart;
